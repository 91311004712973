import React from "react";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import ValuesTest from "../components/ValuesTest";
import DriversTest from "../components/DriversTest";
import CustomTest from "../components/CustomTest";


type testParams = {
    test: string;
}

const Test: React.FC = () => {

    const { test } = useParams<testParams>();

    let chosenTest: React.ReactNode;

    switch (test) {
        case 'ValuesTest':
            chosenTest = <ValuesTest />;
            break;
        case 'driversTest':
            chosenTest = <DriversTest />;
            break;
        default:
            chosenTest = <CustomTest />;
    }

    return (
        <div>
            <Header/>
            <div className="bg-teal-100 mt-24 min-h-screen w-[100%]">
                {chosenTest}
            </div>
        </div>

    );
}

export default Test;
