import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface ProtectedRouteProps {
    children: ReactNode;
    requiredRole?: string;
    allowUnauthenticated: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRole, allowUnauthenticated }) => {
    const token = Cookies.get('token');
    const role = sessionStorage.getItem('role');

    if (role === "admin" && requiredRole !== "admin") {
        return <Navigate to="/adminDashboard" />;
    }

    if (allowUnauthenticated) {
        return <>{children}</>;
    }

    if (!role) {
        return <Navigate to={"/unauthorized"}/>;
    }

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    if (requiredRole && requiredRole !== role) {
        return <Navigate to="/unauthorized" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
