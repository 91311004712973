import React, {useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {useLocation} from "react-router-dom";
import CustomRatingScale from "./CustomRatingScale";
import CustomMultipleOptionTest from "./CustomMultipleOptionTest";

function CustomTest() {

    const [data, setData] = useState({
        _id: 0,
        testTitle: "",
        testDescription: "",
        testInstructions: "",
        questionType : "",
        languageTag: "",
        additionalData: {
            customLabels: [""],
            numberOfOptions: 0
        },
        questions: [{
            question: "",
            options: [{
                optionText: "",
                optionValue: 0
            }]
        }],
        gradingMethod: {
            calculationMethod : "",
            questionsToGroup : "allQuestions",
            groups : [{
                groupName : "",
                groupQuestions : [] as number[]
            }]
        },
        sortingMethod: "",
        feedback: {
            feedbackShowMethod : "",
            feedbacks : [{
                group : -1,
                text : ""
            }]
        }
    });

    const location = useLocation();
    const id = location.state;

    useEffect(() => {
        // Endpoint na backend-u za dohvaćanje podataka o jednom custom testu
        fetch('http://localhost:8080/tests/' + id)
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => toast.error('Failed to fetch test list.'));
    }, [id]);

    return(
        <>
            <div className="p-5 pt-14 text-center">
                <h1 className="text-3xl font-semibold text-red-950">{data.testTitle}</h1>
            </div>
            <div className={'px-10'}>
                <h3 className="pb-3 text-xl font-semibold text-red-950">How to complete the test?</h3>
                <div className={'border-b-[1px] border-b-red-950'}></div>
                <p className={'pt-3 whitespace-pre-line'}>
                    {data.testInstructions}
                </p>
            </div>

            {data.questionType === 'rating-scale' &&
                <CustomRatingScale testData={data}/>}

            {data.questionType === 'multiple-option' && <CustomMultipleOptionTest testData={data}/>}

            <ToastContainer position="bottom-right"
                            autoClose={3000}
                            limit={2}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"/>
        </>
    );
}

export default CustomTest;
