import React from "react";
import Header from "../components/Header";
import {useParams} from "react-router-dom";
import ValuesTestResults from "../components/ValuesTestResults";
import DriversResults from "../components/DriversResults";
import CustomTestResults from "../components/CustomTestResults";


type resultParams = {
    result: string;
}

const TestResults: React.FC = () => {

    const { result } = useParams<resultParams>();

    let chosenResult: React.ReactNode;

    switch (result) {
        case 'ValuesTest':
            chosenResult = <ValuesTestResults />;
            break;
        case 'DriversTest':
            chosenResult = <DriversResults />;
            break;

        default:
            chosenResult = <CustomTestResults />;
    }

    return (
        <div>
            <Header/>
            <div className="bg-teal-100 mt-24 min-h-screen w-full">
                {chosenResult}
            </div>
        </div>

    );
}

export default TestResults;
