import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link, useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import Modal from "react-modal";

interface Tests {
    _id: Number,
    testTitle: String,
    createdBy: String,
    route: String,
    testDescription: String,
    languageTag: String
}
function Home() {
    const { t } = useTranslation();

    const [presetTests, setPresetTests] = useState<Tests[]>([]);
    const [customTests, setCustomTests] = useState<Tests[]>([]);

    const [deleteTestDialogOpen, setDeleteTestDialogOpen] = useState<boolean>(false);
    const [testToDelete, setTestToDelete] = useState<Tests>();

    const [languageFilter, setLanguageFilter] = useState<String | null>("");
    const [filteredCustomTests, setFilteredCustomTests] = useState<Tests[]>([]);

    const navigate = useNavigate();

    const role = sessionStorage.getItem('role');
    const userId = sessionStorage.getItem('userId');

    useEffect(() => {
        // Endpoint na backend-u za dohvaćanje popisa preset testova
        fetch('http://localhost:8080/presetTests')
            .then(response => response.json())
            .then(data => setPresetTests(data))
            .catch(error => toast.error('Failed to fetch test list.'));
    }, []);


    const fetchCustomTests = async () => {
        // Endpoint na backend-u za dohvaćanje popisa custom testova
        await fetch('http://localhost:8080/tests')
            .then(response => response.json())
            .then(data => {
                setCustomTests(data);
                setFilteredCustomTests(data);
            }).catch(err => toast.error('Failed to fetch users list.'));
    }

    useEffect(() => {

        fetchCustomTests().then();

    }, []);


    const handleLanguageFiltering = (language : string) => {
        setLanguageFilter(language);

        if(language !== "") {
            setFilteredCustomTests(customTests.filter(test => test.languageTag === language));
        } else {
            setFilteredCustomTests(customTests);
        }
    }

    const handleDeleteTest = async () => {
        try {
            if (!testToDelete) {
                toast.error("Nema testa za brisanje.");
                return;
            }

            const response = await fetch('http://localhost:8080/deleteTest', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': Cookies.get('token') || ""
                },
                body: JSON.stringify({_id: testToDelete._id})
            });

            if (response.ok) {
                fetchCustomTests().then();
                setDeleteTestDialogOpen(false);
                toast.success(t('DELETE_TEST.success'));
            } else {
                const errorMessage = await response.text();
                toast.error(errorMessage);
            }
        } catch (error) {
            toast.error(t('DELETE_TEST.error'));
        }
    };

    const customActionModalStyles = {
        content: {
            width: '25%',
            height: '25%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
    };

    return (
        <div>
            <Header />
            <div id="home"
                 className="w-full h-screen bg-main-bg bg-cover font-montserrat flex flex-col justify-center mt-16 shadow-2xl">
                <h1 className="text-6xl font-bold text-red-900 pl-10 mb-5">{t('WELCOME')}</h1>
                <p className="text-red-800 font-semibold w-1/2 pl-12">{t('ALL_TESTS')}</p>
            </div>
            <div id="tests" className="flex flex-col font-montserrat pt-24 pr-5 pb-10 pl-5 bg-gray-50">
                <div>
                    <h1 className={'text-2xl text-red-950 mb-5 font-semibold'}>{t('PICK_TEST')}</h1>

                    {presetTests.length === 0 ?
                        <div>
                            <span>{t('NO_TESTS')}</span>
                        </div>
                        :
                        <div className={'grid grid-cols-3 grid-rows-1 gap-5'}>
                            {presetTests.map((test, key) => (
                                <Link to={`/tests/${test.route}`} key={key}>
                                    <div
                                        className="transition-all duration-200 text-center p-5 bg-gray-100 border-red-900 border-[1px] shadow-lg shadow-gray-300 h-full hover:bg-white rounded-lg">
                                        <div className={"flex items-center justify-center mb-3"}>
                                            <h1 className="text-2xl inline mr-2">{test.testTitle}</h1>
                                            <span className={"uppercase font-bold text-red-950"}>{test.languageTag}</span>
                                        </div>
                                        <p className="text-sm">{test.testDescription}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    }
                </div>

                <div>
                    <h1 className={'text-2xl text-red-950 pt-5 font-semibold'}>{t('CHOOSE_CUSTOM_TEST')}</h1>

                    <div className={"flex gap-5 py-2 mb-2 items-center"}>
                        <span className={"block"}>{t('FILTER')}:</span>
                        <button onClick={() => handleLanguageFiltering("en")}
                                className={languageFilter === "en" ? "bg-red-900 text-white font-semibold px-4 py-1 rounded-xl shadow-gray-300" : "bg-white px-4 py-1 shadow-md shadow-gray-400 rounded-xl active:shadow-inner active:shadow-red-950"}>
                            {t('FILTER_EN')}
                        </button>
                        <button onClick={() => handleLanguageFiltering("hr")}
                                className={languageFilter === "hr" ? "bg-red-900 text-white font-semibold px-4 py-1 rounded-xl shadow-gray-300" : "bg-white px-4 py-1 shadow-md shadow-gray-400 rounded-xl active:shadow-inner active:shadow-red-950"}>
                            {t('FILTER_CRO')}
                        </button>
                        <button onClick={() => handleLanguageFiltering("")}
                                className={languageFilter === "" ? "bg-red-900 text-white font-semibold px-4 py-1 rounded-xl shadow-gray-300" : "bg-white px-4 py-1 shadow-md shadow-gray-400 rounded-xl active:shadow-inner active:shadow-red-950"}>
                            {t('FILTER_ALL')}
                        </button>
                    </div>

                    {customTests.length === 0 ?
                        <div>
                            <span>{t('NO_TESTS')}</span>
                        </div>
                        :
                        filteredCustomTests.length === 0 ?
                            <div>
                                <span>{t('NO_FILTERED_TESTS')}</span>
                            </div>
                            :
                            <div className={'grid grid-cols-3 grid-rows-1 gap-5'}>

                            {filteredCustomTests.map((test, key) => (
                                role !== 'user' ?

                                <div key={key} onClick={() => navigate(`/tests/${test.testTitle}`, {state: test._id})}
                                    className="transition-all duration-200 text-center p-5 bg-gray-100 border-red-900 border-[1px] shadow-lg shadow-gray-300 h-full hover:bg-white rounded-lg cursor-pointer">
                                    <div className={"flex items-center justify-center mb-3"}>
                                        <h1 className="text-2xl inline mr-2">{test.testTitle}</h1>
                                        <span className={"uppercase font-bold text-red-950"}>{test.languageTag}</span>
                                    </div>
                                    <p className="text-sm">{test.testDescription}</p>
                                </div>
                                    :
                                <div key={key} className="transition-all duration-200 text-center p-5 bg-gray-100 border-red-900 border-[1px] shadow-lg shadow-gray-300 h-full rounded-lg">
                                    <div className={"flex items-center justify-center mb-3"}>
                                        <h1 className="text-2xl inline mr-2">{test.testTitle}</h1>
                                        <span
                                            className={"uppercase font-bold text-red-950"}>{test.languageTag}</span>
                                    </div>
                                    <p className="text-sm">{test.testDescription}</p>
                                    <div className={"flex gap-2 justify-center"}>
                                        <button className={"w-32 bg-red-900 text-red-50 font-bold p-2 mt-5 rounded-[5px] cursor-pointer text-sm hover:bg-red-700"} onClick={() => navigate(`/tests/${test.testTitle}`, {state: test._id})}>{t('DELETE_TEST.viewTest')}</button>
                                        <button disabled={test.createdBy !== userId} className={test.createdBy !== userId ? "w-32 bg-gray-400 text-red-50 font-bold p-2 mt-5 rounded-[5px] text-sm" : "w-32 bg-red-900 text-red-50 font-bold p-2 mt-5 rounded-[5px] cursor-pointer text-sm hover:bg-red-700"} onClick={() => {
                                            setTestToDelete(test);
                                            setDeleteTestDialogOpen(true);
                                        }
                                        }>{t('DELETE_TEST.delete')}</button>
                                    </div>
                                </div>

                            ))}
                            </div>
                    }

                </div>

            </div>
            <Footer/>

            <Modal
                isOpen={deleteTestDialogOpen}
                onRequestClose={() => setDeleteTestDialogOpen(false)}
                style={customActionModalStyles}
                contentLabel="Brisanje testa"
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
            >
                <div className={"h-full w-full flex flex-col items-center justify-around"}>
                    <h2 className={"font-bold"}>{t('DELETE_TEST.modalTitle')} "{testToDelete?.testTitle}"?</h2>

                    <div className="flex justify-center gap-2 justify-self-end">
                        <button
                            onClick={handleDeleteTest}
                            className="w-32 bg-green-700 text-red-50 font-bold p-2 rounded-[5px] cursor-pointer text-md"
                        >
                            {t('DELETE_TEST.confirm')}
                        </button>
                        <button
                            onClick={() => setDeleteTestDialogOpen(false)}
                            className="w-32 bg-red-600 text-red-50 font-bold p-2 rounded-[5px] cursor-pointer text-md"
                        >
                            {t('DELETE_TEST.cancel')}
                        </button>
                    </div>
                </div>

            </Modal>

            <ToastContainer position="bottom-right"
                            autoClose={3000}
                            limit={2}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"/>
        </div>
    );
}

export default Home;
