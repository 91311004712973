import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";


function ValuesTest() {

    const [values, setValues] = useState({
        name: "",
        description: "",
        questions: [{
            questionNo: 0,
            text: ""
        }],
        results: [{
            id: "",
            header: "",
            text: ""
        }]
    });

    useEffect(() => {
        // Pozivamo endpoint na backend-u za dohvaćanje podataka o testu
        fetch('http://localhost:8080/presetTests/2')
            .then(response => response.json())
            .then(data => setValues(data))
            .catch(error => console.error('Failed to fetch test list:', error));
    }, []);

    const navigate = useNavigate();

    const [answers, setAnswers] = useState({});

    const handleOptionChange = (e: any) => {
        const  { name, value } = e.target;

        setAnswers({
            ...answers,
            [name]: value
        });

    }

    /*Funkcija za provjeru ima li neodgovorenih pitanja*/
    const checkForEmptyAnswers = (answers: {[key: string]: string}) => {
        for(let i = 1; i <= 42; i++) {
            let idx = i.toString();
            if(answers[idx] === undefined) {
                return i;
            }
        }
        return null;
    }

    const handleSubmit = (e : React.FormEvent) => {
        e.preventDefault();

        let emptyAnswerIdx = checkForEmptyAnswers(answers);

        const allQuestionsAnswered = !emptyAnswerIdx && Object.values(answers).length === 42;

        if (allQuestionsAnswered) {
            navigate('/testResults/ValuesTest', { state: {
                    answers
                } });
        } else {
            toast.warning(`Please answer all the questions before submitting. Unanswered question: ${emptyAnswerIdx}`)
        }

    }

    return (
        <>
            <div className="flex flex-col items-center font-montserrat">
                <div className="p-5 pt-14 text-center">
                    <h1 className="text-3xl font-semibold text-red-950">Life Values Inventory</h1>
                </div>

                <div className={'px-10 w-full'}>
                    <h3 className="pb-3 text-xl font-semibold text-red-950">How to complete the test?</h3>
                    <div className={'border-b-[1px] border-b-red-950'}></div>
                    <p className={'pt-3 whitespace-pre-line'}>
                        Read each one and then choose the response (1-5) that best describes how often the belief guides
                        your behavior.
                    </p>
                </div>

                <div className="pt-10 pb-10 pl-0 pr-0 w-2/3 min-[1537px]:w-3/5">
                    <div className={'flex justify-between items-center sticky top-0'}>
                        <h1 className={'font-bold text-red-950 text-2xl'}>VALUES</h1>
                        <div className={'w-[30rem] flex justify-between items-center text-red-800 font-semibold'}>
                            <h1 className={'w-1/5 text-center'}>Almost never guides my behaviour</h1>
                            <div className={'w-1/5'}></div>
                            <h1 className={'w-1/5 text-center'}>Sometimes guides my behaviour</h1>
                            <div className={'w-1/5'}></div>
                            <h1 className={'w-1/5 text-center'}>Almost always guides my behaviour</h1>
                        </div>
                    </div>
                    {values.questions.map((question, idx) => (
                        <div className="flex justify-between" key={idx}>
                            <h3 className="flex items-center font-semibold text-red-950 text-lg">{question.questionNo}. {question.text}</h3>
                            <div className="grid w-[30rem] grid-cols-5 gap-2 bg-white p-2">
                                <div>
                                    <input type="radio" name={`${question.questionNo}`} id={`${question.questionNo}1`}
                                           value="1"
                                           className="peer hidden" onChange={handleOptionChange}/>
                                    <label htmlFor={`${question.questionNo}1`}
                                           className="block transition-all duration-300 cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-red-900 peer-checked:font-bold peer-checked:text-white peer-checked:shadow-md peer-checked:shadow-gray-500">1</label>
                                </div>

                                <div>
                                    <input type="radio" name={`${question.questionNo}`} id={`${question.questionNo}2`}
                                           value="2"
                                           className="peer hidden" onChange={handleOptionChange}/>
                                    <label htmlFor={`${question.questionNo}2`}
                                           className="block transition-all duration-300 cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-red-900 peer-checked:font-bold peer-checked:text-white peer-checked:shadow-md peer-checked:shadow-gray-500">2</label>
                                </div>

                                <div>
                                    <input type="radio" name={`${question.questionNo}`} id={`${question.questionNo}3`}
                                           value="3"
                                           className="peer hidden" onChange={handleOptionChange}/>
                                    <label htmlFor={`${question.questionNo}3`}
                                           className="block transition-all duration-300 cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-red-900 peer-checked:font-bold peer-checked:text-white peer-checked:shadow-md peer-checked:shadow-gray-500">3</label>
                                </div>

                                <div>
                                    <input type="radio" name={`${question.questionNo}`} id={`${question.questionNo}4`}
                                           value="4"
                                           className="peer hidden" onChange={handleOptionChange}/>
                                    <label htmlFor={`${question.questionNo}4`}
                                           className="block transition-all duration-300 cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-red-900 peer-checked:font-bold peer-checked:text-white peer-checked:shadow-md peer-checked:shadow-gray-500">4</label>
                                </div>

                                <div>
                                    <input type="radio" name={`${question.questionNo}`} id={`${question.questionNo}5`}
                                           value="5"
                                           className="peer hidden" onChange={handleOptionChange}/>
                                    <label htmlFor={`${question.questionNo}5`}
                                           className="block transition-all duration-300 cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-red-900 peer-checked:font-bold peer-checked:text-white peer-checked:shadow-md peer-checked:shadow-gray-500">5</label>
                                </div>
                            </div>
                        </div>

                    ))}


                </div>

                <button
                    className="w-32 border-2 border-red-900 bg-red-900 text-red-50 font-bold p-2 mb-7 rounded-[5px] cursor-pointer"
                    onClick={handleSubmit}>Submit
                </button>

            </div>
            <ToastContainer position="bottom-right"
                            autoClose={3000}
                            limit={2}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light" />
        </>


    );
}

export default ValuesTest;
