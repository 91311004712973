import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationHR from './locales/hr/translation.json';
import translationEN from './locales/en/translation.json';

const savedLanguage = localStorage.getItem('language') || 'hr';

void i18n.use(initReactI18next).init({
    resources: {
        hr: {
            translation: translationHR
        },
        en: {
            translation: translationEN
        }
    },
    lng: savedLanguage,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
