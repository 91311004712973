import React, {ChangeEvent, useEffect, useState} from "react";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from "react-toastify";
import Cross from "../assets/cross.png";
import Check from "../assets/check.png";
import Cookies from "js-cookie";
import Modal from 'react-modal';
import ShowPassword from "../assets/show-pass.png";
import HidePassword from "../assets/hide-pass.png";

interface Users {
    name: string
    lastName: string,
    email: string,
    username: string,
    password: string,
    isAdmin : boolean
}

type tableSelectUser = {
    name: string
    lastName: string,
    email: string,
    username: string,
    isAdmin : boolean
}

Modal.setAppElement('#root');
function AdminDashboard() {

    const { t } = useTranslation();

    const [users, setUsers] = useState<Users[]>([]);
    const [editingUser, setEditingUser] = useState<string>("");
    const [userData, setUserData] = useState<tableSelectUser>({
        name: "",
        lastName: "",
        username: "",
        email: "",
        isAdmin: false
    });
    const [newPassword, setNewPassword] = useState<string>("");
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>(false);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState<boolean>(false);
    const [passwordHidden, setPasswordHidden] = useState<boolean>(true);
    const [registerPasswordHidden, setRegisterPasswordHidden] = useState<boolean>(true);

    const [registerUserData, setRegisterUserData] = useState<Users>({
        name: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        isAdmin: false
    });

    const handleSetUserData = (user : Users) => {
        const selection: tableSelectUser = {
            name: user.name,
            lastName: user.lastName,
            email: user.email,
            username: user.username,
            isAdmin: user.isAdmin
        }

        setUserData(selection);
    }

    const handleEditUserData = (username : string | undefined) => {
        if(username) setEditingUser(username);
    }

    const validatePassword = (password : string) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleTextInputChange = (e : ChangeEvent<HTMLInputElement>) => {
        setUserData(prevState => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleRegisterDataChange = (e : ChangeEvent<HTMLInputElement>, inputType : "text" | "checkbox") => {
        setRegisterUserData(prevState => {
            return {
                ...prevState,
                [e.target.name]: inputType === "text" ? e.target.value : e.target.checked
            }
        });
    }

    const handleToggleAdmin = () => {
        const prevAdminStatus = userData.isAdmin;

        setUserData(prevState => {
            return {
                ...prevState,
                isAdmin: !prevAdminStatus
            }
        });
    }
    const handleSaveEditChanges = async () => {

        const dataToSave = {
            name: userData.name,
            lastName: userData.lastName,
            email: userData.email,
            oldUsername: editingUser,
            newUsername: userData.username,
            isAdmin: userData.isAdmin
        }

        try {
            const response = await fetch('http://localhost:8080/editUserData', {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'x-auth-token' : Cookies.get('token') || ""
                },
                body: JSON.stringify(dataToSave)
            });

            if(response.ok) {
                toast.success("User data changed successfully.");
                fetchData().then();
                setUserData({
                    name: "",
                    lastName: "",
                    username: "",
                    email: "",
                    isAdmin: false
                });
                setEditingUser("");
            } else {
                const errMessage = await response.text();
                toast.error(errMessage);
            }
        } catch (err) {
            toast.error('Failed to change user data');
        }
    }


    const handlePasswordDialogOpen = () => {
        setChangePasswordDialogOpen(true);
    }

    const handleNewPasswordInput = (e : ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    }

    const handleChangePassword = async () => {
        if (!validatePassword(newPassword)) {
            toast.error("Lozinka nije validna. Mora sadržavati barem 1 veliko slovo, 1 broj, 1 specijalni znak i biti najmanje 8 znakova duga.");
        } else {
            const dataToSend = {
                username: userData.username,
                newPassword: newPassword
            }

            try {
                const response = await fetch('http://localhost:8080/resetPassword', {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json',
                        'x-auth-token' : Cookies.get('token') || ""
                    },
                    body: JSON.stringify(dataToSend)
                });

                if(response.ok) {
                    toast.success("Password changed successfully.");
                    fetchData().then();
                    setUserData({
                        name: "",
                        lastName: "",
                        username: "",
                        email: "",
                        isAdmin: false
                    });
                    setEditingUser("");
                    setChangePasswordDialogOpen(false);
                } else {
                    const errMessage = await response.text();
                    toast.error(errMessage);
                }
            } catch (err) {
                toast.error('Failed to change password');
            }
        }
    }


    const handleDeleteUser = async () => {
        try {
            if (!userData.username) {
                toast.error("Nema korisnika za brisanje.");
                return;
            }

            const response = await fetch('http://localhost:8080/deleteUser', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': Cookies.get('token') || ""
                },
                body: JSON.stringify({username: userData.username})
            });

            if (response.ok) {
                fetchData().then();
                toast.success("Korisnik uspješno obrisan.");
            } else {
                const errorMessage = await response.text();
                toast.error(errorMessage);
            }
        } catch (error) {
            toast.error("Došlo je do greške pri brisanju korisnika.");
        }
    };

    const handleQuitEditing = () => {
        setUserData({
            name: "",
            lastName: "",
            username: "",
            email: "",
            isAdmin: false
        });
        setEditingUser("");
    }

    const handleRegisterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validatePassword(registerUserData.password)) {
            toast.error("Lozinka nije validna. Mora sadržavati barem 1 veliko slovo, 1 broj, 1 specijalni znak i biti najmanje 8 znakova duga.");
        } else {
            try {
                const response = await fetch('http://localhost:8080/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json',
                        'x-auth-token' : Cookies.get('token') || ""
                    },
                    body: JSON.stringify(registerUserData)
                });

                if(response.ok) {
                    toast.success("User registered successfully.");
                    fetchData().then();
                    setRegisterUserData({
                        name: "",
                        lastName: "",
                        username: "",
                        email: "",
                        password: "",
                        isAdmin: false
                    });
                } else {
                    const errMessage = await response.text();
                    toast.error(errMessage);
                }
            } catch (err) {
                toast.error('Failed to register user.');
            }
        }
    }

    const fetchData = async () => {
        try {
            // Endpoint na backend-u za dohvaćanje podataka o korisnicima
            await fetch('http://localhost:8080/users', {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json',
                    'x-auth-token' : Cookies.get('token') || ""
                }
            }).then(response => response.json())
                .then(data => {
                    setUsers(data);
                })
        } catch (err) {
            toast.error('Failed to fetch users list.');
        }
    }

    useEffect(() => {

        fetchData().then();

    }, []);


    const customPassModalStyles = {
        content: {
            width: '33%',
            height: '33%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
    };

    const customActionModalStyles = {
        content: {
            width: '25%',
            height: '25%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
    };

    const toggleShowPass = (mode : "register" | "change") => {
        switch (mode) {
            case "change":
                setPasswordHidden(prevState => !prevState);
                break;
            case "register":
                setRegisterPasswordHidden(prevState => !prevState);
                break;
        }
    }

    return(
        <div>
            <Header/>
            <div className={"w-full h-[calc(100vh-96px)] bg-teal-100 mt-[96px]"}>
                <div className={"w-full h-full flex"}>
                    <div className={"w-1/2 flex flex-col items-center p-5 pr-0"}>
                        <h1 className="text-xl font-semibold text-red-950 mb-5">{t('ADMIN_DASHBOARD.users')}</h1>
                        <div className={"w-full h-full flex flex-col bg-white"}>
                            <div className={"flex w-full h-10 text-md font-bold items-center text-white bg-red-900 pr-3"}>
                                <div className={"w-1/5 flex justify-center"}>
                                    <h1>{t('REGISTER.name')}</h1>
                                </div>
                                <div className={"w-1/5 flex justify-center"}>
                                    <h1>{t('REGISTER.surname')}</h1>
                                </div>
                                <div className={"w-1/5 flex justify-center"}>
                                    <h1>{t('REGISTER.username')}</h1>
                                </div>
                                <div className={"w-1/5 flex justify-center"}>
                                    <h1>{t('REGISTER.email')}</h1>
                                </div>
                                <div className={"w-1/5 flex justify-center"}>
                                    <h1>{t('REGISTER.adminStatus')}</h1>
                                </div>
                            </div>
                            <div className={"h-[calc(100%-8rem)] overflow-y-scroll"}>
                                {users?.map((user, index) => (
                                    <div key={index} className={userData.username === user.username ? "flex bg-red-100 border border-red-950 rounded h-10 items-center" : index % 2 === 0 ? "flex bg-gray-100 h-10 items-center" : "flex h-10 items-center"}>

                                        <div className={"w-1/5 h-full flex justify-center items-center cursor-pointer"} {...(editingUser === "" && { onClick: () => handleSetUserData(user)})}>
                                            {editingUser === user.username ?
                                                <input type={"text"} name={"name"} value={userData.name}
                                                       className={"w-full h-full mx-1 px-2 border box-border"} onChange={handleTextInputChange}/>
                                                : <span>{user.name}</span>
                                            }
                                        </div>
                                        <div className={"w-1/5 h-full flex justify-center items-center cursor-pointer"} {...(editingUser === "" && { onClick: () => handleSetUserData(user)})}>
                                            {editingUser === user.username ?
                                                <input type={"text"} name={"lastName"} value={userData.lastName}
                                                       className={"w-full h-full mx-1 px-2 border box-border"} onChange={handleTextInputChange}/>
                                                : <span>{user.lastName}</span>
                                            }

                                        </div>
                                        <div className={"w-1/5 h-full flex justify-center items-center cursor-pointer"} {...(editingUser === "" && { onClick: () => handleSetUserData(user)})}>
                                            {editingUser === user.username ?
                                                <input type={"text"} name={"username"} value={userData.username}
                                                       className={"w-full h-full mx-1 px-2 border box-border"} onChange={handleTextInputChange}/>
                                                 : <span>{user.username}</span>
                                            }
                                        </div>
                                        <div className={"w-1/5 h-full flex justify-center items-center cursor-pointer"} {...(editingUser === "" && { onClick: () => handleSetUserData(user)})}>
                                            {editingUser === user.username ?
                                                <input type={"text"} name={"email"} value={userData.email}
                                                       className={"w-full h-full mx-1 px-2 border box-border"} onChange={handleTextInputChange}/>
                                                 : <span>{user.email}</span>
                                            }
                                        </div>
                                        <div className={"w-1/5 h-full flex justify-center items-center cursor-pointer"} {...(editingUser === "" && { onClick: () => handleSetUserData(user)})}>
                                            {editingUser === user.username ?
                                                (
                                                    <img src={userData.isAdmin ? Check : Cross}
                                                         alt={userData.isAdmin ? "Not admin" : "Admin"}
                                                         onClick={handleToggleAdmin}
                                                         className={"w-8 h-8 bg-white"}/>
                                                ) :
                                                (
                                                    <img src={user.isAdmin ? Check : Cross}
                                                         alt={!user.isAdmin ? "Not admin" : "Admin"}
                                                         className={"w-8 h-8"}/>
                                                )}

                                        </div>
                                    </div>
                                ))}
                            </div>
                            {editingUser !== "" ?
                                <div className={"flex gap-1.5 w-full justify-center"}>
                                    <button
                                        className={"w-40 h-10 bg-green-700 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"} onClick={handleSaveEditChanges}>{t('ADMIN_DASHBOARD.submitChanges')}
                                    </button>
                                    <button
                                        className={"w-40 h-10 bg-red-900 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"} onClick={handleQuitEditing}>{t('ADMIN_DASHBOARD.quitEditing')}
                                    </button>
                                </div> :
                                <div className={"flex gap-1.5 w-full justify-center"}>
                                    <button disabled={userData.username === ""} onClick={() => handleEditUserData(userData.username)}
                                        className={userData.username === "" ? "w-40 h-10 bg-red-400 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm" : "w-40 h-10 bg-red-900 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"}>{t('ADMIN_DASHBOARD.startEditing')}
                                    </button>
                                    <button disabled={userData.username === ""} onClick={() => handlePasswordDialogOpen()}
                                        className={userData.username === "" ? "w-40 h-10 bg-red-400 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm" : "w-40 h-10 bg-red-900 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"}>{t('ADMIN_DASHBOARD.changePassword')}
                                    </button>
                                    <button disabled={userData.username === ""} onClick={() => setDeleteUserDialogOpen(true)}
                                        className={userData.username === "" ? "w-40 h-10 bg-red-400 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm" : "w-40 h-10 bg-red-600 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"}>{t('ADMIN_DASHBOARD.removeUser')}
                                    </button>
                                </div>
                            }

                        </div>
                    </div>

                    <div className={"w-1/2 flex flex-col items-center p-5"}>
                        <h1 className="text-xl font-semibold text-red-950 mb-5">{t('ADMIN_DASHBOARD.addNewUser')}</h1>
                        <div className={"bg-white h-2/3 w-5/6 rounded-xl shadow-md"}>

                            <form method={"POST"} onSubmit={handleRegisterSubmit}
                                                      className={"flex flex-col gap-2.5 py-10 h-full justify-center"}>
                                <div className={"w-full flex justify-center items-center"}>
                                <label className={"inline-block w-[150px] font-semibold text-red-950"}>{t('REGISTER.name')}:</label>
                                    <input type="text" name={"name"} value={registerUserData.name} required className={"border-red-950 border-2 rounded h-9 w-1/3 p-2"} onChange={(e) => handleRegisterDataChange(e, "text")}/>
                                </div>

                                <div className={"w-full flex justify-center items-center"}>
                                    <label className={"inline-block w-[150px] font-semibold text-red-950"}>{t('REGISTER.surname')}:</label>
                                    <input type="text" name={"lastName"} value={registerUserData.lastName} required className={"border-red-950 border-2 rounded h-9 w-1/3 p-2"} onChange={(e) => handleRegisterDataChange(e, "text")}/>
                                </div>

                                <div className={"w-full flex justify-center items-center"}>
                                    <label className={"inline-block w-[150px] font-semibold text-red-950"}>{t('REGISTER.email')}:</label>
                                    <input type="email" name={"email"} value={registerUserData.email} required className={"border-red-950 border-2 rounded h-9 w-1/3 p-2"} onChange={(e) => handleRegisterDataChange(e, "text")}/>
                                </div>

                                <div className={"w-full flex justify-center items-center"}>
                                    <label className={"inline-block w-[150px] font-semibold text-red-950"}>{t('REGISTER.username')}:</label>
                                    <input type="text" name={"username"} value={registerUserData.username} required className={"border-red-950 border-2 rounded h-9 w-1/3 p-2"} onChange={(e) => handleRegisterDataChange(e, "text")}/>
                                </div>

                                <div className={"w-full flex justify-center items-center"}>
                                    <label className={"inline-block w-[150px] font-semibold text-red-950"}>{t('REGISTER.password')}:</label>
                                    <div className={"w-1/3 flex relative items-center"}>
                                        <input type={registerPasswordHidden ? "password" : "text"} name={"password"} value={registerUserData.password} required
                                               className={"border-red-950 border-2 rounded h-9 w-full p-2"}
                                               onChange={(e) => handleRegisterDataChange(e, "text")}/>
                                        <img src={registerPasswordHidden ? ShowPassword : HidePassword} alt="show password icon"
                                             className={"absolute end-0 mr-2"} onClick={() => toggleShowPass("register")}/>
                                    </div>

                                </div>

                                <div className={"w-full flex justify-center items-center"}>
                                <label className={"inline-block w-[150px] font-semibold text-red-950"}>{t('REGISTER.adminStatus')}:</label>
                                    <div className={"w-1/3 h-9 flex items-center"}>
                                        <input name={"isAdmin"} type="checkbox" className={"w-5 h-5"} onChange={(e) => handleRegisterDataChange(e, "checkbox")}/>
                                    </div>

                                </div>

                                <div className={"self-center"}>
                                    <button type={"submit"}
                                            className={"w-32 bg-red-900 text-red-50 font-bold p-2 mt-5 rounded-[5px] cursor-pointer text-md"}>{t('ADMIN_DASHBOARD.registerUser')}
                                    </button>
                                </div>

                            </form>


                        </div>
                    </div>
                </div>

            </div>

            <Modal
                isOpen={changePasswordDialogOpen}
                onRequestClose={() => setChangePasswordDialogOpen(false)}
                style={customPassModalStyles}
                contentLabel="Promjena lozinke"
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
            >
                <div className={"h-full w-full flex flex-col items-center justify-around"}>
                    <h2 className={"font-bold"}>{t('ADMIN_DASHBOARD_PASSWORD_MODAL.title')} {userData.username}</h2>

                    <div className={"w-2/3 flex relative items-center"}>
                        <input
                            type={passwordHidden ? "password" : "text"}
                            value={newPassword}
                            onChange={handleNewPasswordInput}
                            className="w-full border-red-950 border-2 rounded h-9 p-2"
                        />
                        <img src={passwordHidden ? ShowPassword : HidePassword} alt="show password icon"
                             className={"absolute end-0 mr-2"} onClick={() => toggleShowPass("change")}/>
                    </div>


                    <div className="flex justify-center gap-2 justify-self-end">
                        <button
                            onClick={handleChangePassword}
                            className="w-32 bg-green-700 text-red-50 font-bold p-2 rounded-[5px] cursor-pointer text-md"
                        >
                            {t('ADMIN_DASHBOARD_PASSWORD_MODAL.submitChange')}
                        </button>
                        <button
                            onClick={() => setChangePasswordDialogOpen(false)}
                            className="w-32 bg-red-600 text-red-50 font-bold p-2 rounded-[5px] cursor-pointer text-md"
                        >
                            {t('ADMIN_DASHBOARD_PASSWORD_MODAL.quit')}
                        </button>
                    </div>
                </div>

            </Modal>

            <Modal
                isOpen={deleteUserDialogOpen}
                onRequestClose={() => setDeleteUserDialogOpen(false)}
                style={customActionModalStyles}
                contentLabel="Brisanje korisnika"
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
            >
                <div className={"h-full w-full flex flex-col items-center justify-around"}>
                    <h2 className={"font-bold"}>{t('ADMIN_DASHBOARD_ACTION_MODAL.title')} {userData.username}?</h2>

                    <div className="flex justify-center gap-2 justify-self-end">
                        <button
                            onClick={handleDeleteUser}
                            className="w-32 bg-green-700 text-red-50 font-bold p-2 rounded-[5px] cursor-pointer text-md"
                        >
                            {t('ADMIN_DASHBOARD_ACTION_MODAL.submitChange')}
                        </button>
                        <button
                            onClick={() => setDeleteUserDialogOpen(false)}
                            className="w-32 bg-red-600 text-red-50 font-bold p-2 rounded-[5px] cursor-pointer text-md"
                        >
                            {t('ADMIN_DASHBOARD_ACTION_MODAL.quit')}
                        </button>
                    </div>
                </div>

            </Modal>

            <ToastContainer position="bottom-right"
                            autoClose={3000}
                            limit={2}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"/>


        </div>
    )
}

export default AdminDashboard;
