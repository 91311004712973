import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";


type Answers = { [key : string] : number };

function DriversTest() {

    const [drivers, setDrivers] = useState({
        name: "",
        description: "",
        questions: [{
            questionNo: 0,
            options: [{
                id: "",
                text: ""
            }]
        }],
        results: [{
            driver: "",
            strengths: [""],
            weaknesses: [""],
            effectiveness: [""],
            destructiveness: [""],
            changePoints: [""],
            handling: [""],
            advice: {
                basis: "",
                predictedBehaviour: "",
                consequence: "",
                worksWell: "",
                improvement: "",
                managerHelp: ""
            },
            pairs: [
                {
                    question: 0,
                    choice: ""
                }
            ]

        }]
    });

    useEffect(() => {
        // Pozivamo endpoint na backend-u za dohvaćanje podataka o testu
        fetch('http://localhost:8080/presetTests/3')
            .then(response => response.json())
            .then(data => setDrivers(data))
            .catch(error => console.error('Failed to fetch test list:', error));
    }, []);



    const navigate = useNavigate();
    const [answers, setAnswers] = useState<Answers>({});

    const lowMarks : number[] = [0, 1, 2, 3];
    const highMarks : number[] = [7, 8, 9, 10];

    const disableChoice = (id : string) => {
        const toDisable = document.getElementsByClassName(id);

        for(let i = 0; i < toDisable.length; i++) {
            if(toDisable[i]) {
                toDisable[i].setAttribute('disabled', 'true');
                toDisable[i].classList.remove('text-red-800', 'font-semibold');
                toDisable[i].classList.add('bg-gray-200');
            }
        }

    }

    const enableChoice = (id : string) => {
        const toEnable = document.getElementsByClassName(id);

        for(let i = 0; i < toEnable.length; i++) {
            if(toEnable[i]) {
                toEnable[i].removeAttribute('disabled');
                toEnable[i].classList.remove('bg-gray-200');
                toEnable[i].classList.add('text-red-800', 'font-semibold');
            }
        }

    }

    const handleChange = (e : any) => {
        const { name, value } = e.target;

        let id = name.split('#')[0] + value;

        if(value === "") {
            if(lowMarks.includes(parseInt(answers[name].toString()))) {
                for(let i = 0; i < lowMarks.length; i++) {
                    enableChoice(name.split('#')[0] + lowMarks[i]);
                }
            } else if(highMarks.includes(parseInt(answers[name].toString()))) {
                for(let i = 0; i < highMarks.length; i++) {
                    enableChoice(name.split('#')[0] + highMarks[i]);
                }
            } else {
                enableChoice(name.split('#')[0] + answers[name]);
            }
        } else if(value !== "") {
            if(lowMarks.includes(parseInt(value))) {
                if(answers[name] && highMarks.includes(parseInt(answers[name].toString()))) {
                    for(let i = 0; i < highMarks.length; i++) {
                        enableChoice(name.split('#')[0] + highMarks[i]);
                    }
                    for(let i = 0; i < lowMarks.length; i++) {
                        disableChoice(name.split('#')[0] + lowMarks[i]);
                    }
                } else {
                    enableChoice(name.split('#')[0] + answers[name]);
                    for(let i = 0; i < lowMarks.length; i++) {
                        disableChoice(name.split('#')[0] + lowMarks[i]);
                    }
                }

            } else if(highMarks.includes(parseInt(value))) {
                if(answers[name] && lowMarks.includes(parseInt(answers[name].toString()))) {
                    for(let i = 0; i < lowMarks.length; i++) {
                        enableChoice(name.split('#')[0] + lowMarks[i]);
                    }
                    for(let i = 0; i < highMarks.length; i++) {
                        disableChoice(name.split('#')[0] + highMarks[i]);
                    }
                } else {
                    enableChoice(name.split('#')[0] + answers[name]);
                    for(let i = 0; i < highMarks.length; i++) {
                        disableChoice(name.split('#')[0] + highMarks[i]);
                    }
                }
            } else {
                if(answers[name]) {
                    if(lowMarks.includes(parseInt(answers[name].toString()))) {
                        disableChoice(id);
                        for(let i = 0; i < lowMarks.length; i++) {
                            enableChoice(name.split('#')[0] + lowMarks[i]);
                        }
                    } else if(highMarks.includes(parseInt(answers[name].toString()))) {
                        disableChoice(id);
                        for(let i = 0; i < highMarks.length; i++) {
                            enableChoice(name.split('#')[0] + highMarks[i]);
                        }
                    } else {
                        enableChoice(name.split('#')[0] + answers[name]);
                        disableChoice(id);
                    }
                }
                enableChoice(name.split('#')[0] + answers[name]);
                disableChoice(id);
            }

        } else {
            disableChoice(id);
        }

        setAnswers({
            ...answers,
            [name] : value
        });
    }

    /*Funkcija za provjeru ima li neodgovorenih pitanja*/
    const checkForEmptyAnswers = (answers: {[key: string]: number}) => {
        for(let i = 1; i <= 5; i++) {
            for(let j = 97; j <= 101; j++) {
                let ch = String.fromCharCode(j);
                let str = i+'#'+ch;

                let ret = {
                    question: -1,
                    option: ''
                }

                if(answers[str] === undefined || answers[str].toString() === '') {
                    ret.question = i;
                    ret.option = ch;

                    return ret;
                }

            }

        }
        return null;
    }

    const handleSubmit = (e : React.FormEvent) => {
        e.preventDefault();

        let emptyAnswerIdx = checkForEmptyAnswers(answers);

        const allQuestionsAnswered = !emptyAnswerIdx && Object.values(answers).length === 60;

        if (allQuestionsAnswered) {
            navigate('/testResults/DriversTest', { state: {
                    answers
                } });
        } else {
            toast.warning(`Please answer all the questions before submitting. Unanswered question: ${emptyAnswerIdx?.question}. ${emptyAnswerIdx?.option})`);
        }

    }

    return(
        <>
            <div className="p-5 pt-14 font-montserrat text-center">
                <h1 className="text-3xl font-semibold text-red-950">Drivers Questionnaire</h1>
            </div>
            <div className={'px-10 font-montserrat'}>
                <h3 className="pb-3 text-xl font-semibold text-red-950">How to complete the questionnaire?</h3>
                <div className={'border-b-[1px] border-b-red-950'}></div>
                <p className={'pt-3 whitespace-pre-line'}>
                    1. Take each number in turn.{'\n'}
                    2. From the five descriptions pick out the one which is the most true for you and give it a high mark (between 7 and 10).{'\n'}
                    3. Take the description that is least true for you and give it a low mark (between 0 and 3).{'\n'}
                    4. Arrange the other three statements between, giving each a mark which ranks them between your lowest and highest.
                </p>
            </div>
            <div className={'mt-10 flex flex-col justify-center items-center font-montserrat'}>
                <form className={'bg-white shadow-xl px-14 py-4 flex flex-col mb-10'}>
                    {drivers.questions.map((question, key) => (
                        <div key={key} className={'py-3 flex flex-col gap-3'}>
                            <h3 className={'font-bold'}>{question.questionNo}</h3>

                            {question.options.map((opt, innerKey) => (
                                <div key={innerKey} className={'flex justify-between'}>
                                    <div className={'mr-10'}>
                                        <span>{opt.id}) </span>
                                        <span>{opt.text}</span>
                                    </div>
                                    <div>
                                        <select name={`${question.questionNo}#${opt.id}`}
                                                id={`${question.questionNo}${opt.id}`} onChange={handleChange}>
                                            <option value="">...</option>
                                            <option value={0} id={`${question.questionNo}0`}
                                                    className={`${question.questionNo}0 text-red-800 font-semibold`}>0
                                            </option>
                                            <option value={1} id={`${question.questionNo}1`}
                                                    className={`${question.questionNo}1 text-red-800 font-semibold`}>1
                                            </option>
                                            <option value={2} id={`${question.questionNo}2`}
                                                    className={`${question.questionNo}2 text-red-800 font-semibold`}>2
                                            </option>
                                            <option value={3} id={`${question.questionNo}3`}
                                                    className={`${question.questionNo}3 text-red-800 font-semibold`}>3
                                            </option>
                                            <option value={4} id={`${question.questionNo}4`}
                                                    className={`${question.questionNo}4 text-red-800 font-semibold`}>4
                                            </option>
                                            <option value={5} id={`${question.questionNo}5`}
                                                    className={`${question.questionNo}5 text-red-800 font-semibold`}>5
                                            </option>
                                            <option value={6} id={`${question.questionNo}6`}
                                                    className={`${question.questionNo}6 text-red-800 font-semibold`}>6
                                            </option>
                                            <option value={7} id={`${question.questionNo}7`}
                                                    className={`${question.questionNo}7 text-red-800 font-semibold`}>7
                                            </option>
                                            <option value={8} id={`${question.questionNo}8`}
                                                    className={`${question.questionNo}8 text-red-800 font-semibold`}>8
                                            </option>
                                            <option value={9} id={`${question.questionNo}9`}
                                                    className={`${question.questionNo}9 text-red-800 font-semibold`}>9
                                            </option>
                                            <option value={10} id={`${question.questionNo}10`}
                                                    className={`${question.questionNo}10 text-red-800 font-semibold`}>10
                                            </option>
                                        </select>
                                    </div>


                                </div>
                            ))}
                        </div>
                    ))}

                    <button
                        className="self-center w-32 border-2 border-red-900 bg-red-900 text-red-50 font-bold p-2 mt-5 rounded-[5px] cursor-pointer"
                        onClick={handleSubmit}>Submit
                    </button>

                </form>
            </div>
            <ToastContainer position="bottom-right"
                            autoClose={3000}
                            limit={2}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light" />
        </>
    );
}

export default DriversTest;
