import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <div id="contact" className="bg-red-950 w-full h-40 font-montserrat">
            <footer className="grid grid-cols-3 grid-rows-1 items-center h-full">
                <div className="text-white text-lg text-center flex flex-col">
                    <h1 className="font-bold text-2xl">{t('FOOTER_CONTACT')}</h1>
                    <span>Email: dora.kasik@fer.hr</span>
                    <span>Tel: +385 91/512-8502</span>
                </div>
                <div className="text-white text-lg text-center flex flex-col">
                    <p className="copyright">&copy; 2023 Dora Kašik</p>
                </div>
                <div className="text-white text-lg text-center flex flex-col">
                    <h1 className="font-bold text-2xl">Mentor</h1>
                    <div className="about-info">
                        <p>izv. prof. dr. sc. {' '}
                            <Link to="https://www.fer.unizg.hr/predrag.pale" className={"underline"}>
                                Predrag Pale
                            </Link>
                            </p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer;
