import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface Option {
    optionText: string;
    optionValue: number;
}
interface Question {
    question: string;
    options: Option[];
}

interface AdditionalData {
    customLabels: string[];
    numberOfOptions: number;
}


interface Group {
    groupName: string,
    groupQuestions: number[]
}
interface GradingMethod {
    calculationMethod: string;
    questionsToGroup: string;
    groups: Group[];
}

interface Feedback {
    group: number;
    text: string;
}

interface CompleteFeedback {
    feedbackShowMethod: string;
    feedbacks: Feedback[];
}
interface LayoutProps {
    _id: number;
    testTitle: string;
    testDescription: string;
    testInstructions: string;
    questionType: string;
    languageTag: string;
    additionalData: AdditionalData;
    questions: Question[];
    gradingMethod: GradingMethod;
    sortingMethod: string;
    feedback: CompleteFeedback;
}

interface Data {
    testData: LayoutProps
}
const CustomMultipleOptionTest: React.FC<Data> = ({ testData }) => {

    const navigate = useNavigate();

    const [answers, setAnswers] = useState({});

    const handleOptionChange = (e: any) => {
        const  { name, value } = e.target;

        setAnswers({
            ...answers,
            [name]: value
        });

    }

    const {t} = useTranslation();

    const checkForEmptyAnswers = (answers: {[key: string]: string}) => {
        for(let i = 1; i <= testData.questions.length; i++) {
            let idx = i.toString();
            if(answers[idx] === undefined) {
                return i;
            }
        }
        return null;
    }


    const generateRoute = (testTitle : string) => {
        if (!testTitle) {
            return "";
        }
        return testTitle.replace(/\s+/g, '-');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        let emptyAnswerIdx = checkForEmptyAnswers(answers);

        const allQuestionsAnswered = !emptyAnswerIdx && Object.values(answers).length === testData.questions.length;

        const route = generateRoute(testData.testTitle);

        if (allQuestionsAnswered) {
            navigate(`/testResults/${route}`, { state: {
                    answers,
                    data: testData
                } });
        } else {
            toast.warning(`Please answer all the questions before submitting. Unanswered question: ${emptyAnswerIdx}`)
        }

    }

    return(
        <div className={"flex flex-col w-full p-10"}>

            <form onSubmit={handleSubmit} className="bg-white p-10 flex flex-col gap-5 shadow-xl">

                {testData.questions.map((question, idx) => (
                    <div key={idx}>

                        <div>
                            <span className={"font-bold mr-5 text-red-950"}>{idx + 1}.</span>
                            <span className={"font-semibold text-red-950"}>{question.question}</span>

                            <div className={"flex flex-col gap-1.5 mt-5"}>
                                {question.options.map((option, optionIndex) => (
                                    <div key={optionIndex}>
                                        <label className="flex items-center cursor-pointer">
                                            <input type={"radio"} name={`${idx + 1}`}
                                                   value={option.optionValue} onChange={handleOptionChange}
                                                   className="cursor-pointer outline-none transition-all duration-200 ease-in min-w-[30px] w-[30px] h-[30px] mr-4 appearance-none border-none bg-gray-200 hover:bg-red-900 focus:bg-red-950 checked:bg-red-950 rounded-xl"/>
                                                {option.optionText}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                ))}

                <button type={"submit"}
                    className="self-center w-32 border-2 border-red-900 bg-red-900 text-red-50 font-bold p-2 mt-10 rounded-[5px] cursor-pointer"
                    >{t('submitButton')}
                </button>

            </form>


        </div>
    )
}

export default CustomMultipleOptionTest;
