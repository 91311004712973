import React, {useState} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


interface Option {
    optionText: string;
    optionValue: number;
}
interface Question {
    question: string;
    options: Option[];
}

interface AdditionalData {
    customLabels: string[];
    numberOfOptions: number;
}


interface Group {
    groupName: string,
    groupQuestions: number[]
}
interface GradingMethod {
    calculationMethod: string;
    questionsToGroup: string;
    groups: Group[];
}

interface Feedback {
    group: number;
    text: string;
}

interface CompleteFeedback {
    feedbackShowMethod: string;
    feedbacks: Feedback[];
}
interface LayoutProps {
    _id: number;
    testTitle: string;
    testDescription: string;
    testInstructions: string;
    questionType: string;
    languageTag: string;
    additionalData: AdditionalData;
    questions: Question[];
    gradingMethod: GradingMethod;
    sortingMethod: string;
    feedback: CompleteFeedback;
}

interface Data {
    testData: LayoutProps
}

const CustomRatingScale: React.FC<Data> = ({ testData }) => {

    const {t} = useTranslation();

    const [answers, setAnswers] = useState({});
    const handleOptionChange = (e: any) => {
        const  { name, value } = e.target;

        setAnswers({
            ...answers,
            [name]: value
        });

    }

    const optionWidth = `${100 / testData.additionalData.customLabels.length}%`;

    const navigate = useNavigate();

    const checkForEmptyAnswers = (answers: {[key: string]: string}) => {
        for(let i = 1; i <= testData.questions.length; i++) {
            let idx = i.toString();
            if(answers[idx] === undefined) {
                return i;
            }
        }
        return null;
    }


    const generateRoute = (testTitle : string) => {
        if (!testTitle) {
            return "";
        }
        return testTitle.replace(/\s+/g, '-');
    };

    const handleSubmit = (e : React.FormEvent) => {
        e.preventDefault();

        let emptyAnswerIdx = checkForEmptyAnswers(answers);

        const allQuestionsAnswered = !emptyAnswerIdx && Object.values(answers).length === testData.questions.length;

        const route = generateRoute(testData.testTitle);

        if (allQuestionsAnswered) {
            navigate(`/testResults/${route}`, { state: {
                    answers,
                    data: testData
                } });
        } else {
            toast.warning(`Please answer all the questions before submitting. Unanswered question: ${emptyAnswerIdx}`)
        }

    }

    return(
        <div className={"flex flex-col w-full p-10"}>

            <div className={testData.additionalData.numberOfOptions <= 5 ? "w-2/3 flex gap-1 self-center justify-between items-center sticky top-0" : "w-full flex gap-1 self-center justify-between items-center sticky top-0"}>
                <div></div>
                <div className={testData.additionalData.numberOfOptions <= 5 ? "w-2/5 flex justify-between items-center text-red-800 font-semibold" : "w-3/5 flex justify-between items-center text-red-800 font-semibold"}>
                    {testData.additionalData.customLabels.map((label, index) => (
                        <React.Fragment key={index}>
                            {testData.additionalData.customLabels[index] === null ?
                                <div style={{width: optionWidth}}></div>
                                :
                                <h1 className={"text-center"} style={{width: optionWidth}}>{label}</h1>
                            }

                        </React.Fragment>

                    ))}
                </div>
            </div>

            <form className={testData.additionalData.numberOfOptions <= 5 ? "w-2/3 self-center" : "w-full self-center"} onSubmit={handleSubmit}>
                {testData.questions.map((question, questionIdx) => (
                    <div
                        className={testData.additionalData.numberOfOptions <= 5 ? "flex gap-1 justify-between self-center" : "flex gap-1 justify-between self-center"}
                        key={questionIdx}>
                        <div className={"flex items-center max-w-[50%]"}>
                            <span className="font-semibold text-red-950 text-lg w-10">{questionIdx + 1}.</span>
                            <span className={"font-semibold text-red-950 text-lg"}>{question.question}</span>
                        </div>

                        <div
                            className={testData.additionalData.numberOfOptions <= 5 ? "flex gap-1 w-2/5 bg-white p-2 justify-center" : "flex gap-1 w-3/5 bg-white p-2"}>

                            {[...Array(testData.additionalData.numberOfOptions)].map((_, optionIdx) => (
                                <div key={optionIdx} style={{width: optionWidth}} className={"min-w-[50px]"}>
                                    <input type="radio" name={`${questionIdx + 1}`} id={`${questionIdx}${optionIdx + 1}`}
                                           value={optionIdx + 1}
                                           className="peer hidden" onChange={handleOptionChange}/>
                                    <label htmlFor={`${questionIdx}${optionIdx + 1}`}
                                           className="block transition-all duration-300 cursor-pointer select-none rounded-3xl p-2 text-center peer-checked:bg-red-900 peer-checked:font-bold peer-checked:text-white peer-checked:shadow-md peer-checked:shadow-gray-500">
                                        {optionIdx + 1}
                                    </label>
                                </div>
                            ))}

                        </div>
                    </div>

                ))}

                <div className={"flex justify-center mt-10"}>
                    <button
                        type={"submit"}
                        className="w-32 border-2 border-red-900 bg-red-900 text-red-50 font-bold p-2 mb-7 rounded-[5px] cursor-pointer">
                        {t('submitButton')}
                    </button>
                </div>
            </form>


        </div>
    );
}

export default CustomRatingScale;
