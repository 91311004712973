import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

function DriversResults() {

    const [drivers, setDrivers] = useState({
        name: "",
        description: "",
        questions: [{
            questionNo: 0,
            options: [{
                id: "",
                text: ""
            }]
        }],
        results: [{
            driver: "",
            strengths: [""],
            weaknesses: [""],
            effectiveness: [""],
            destructiveness: [""],
            changePoints: [""],
            handling: [""],
            advice: {
                basis: "",
                predictedBehaviour: "",
                consequence: "",
                worksWell: "",
                improvement: "",
                managerHelp: ""
            },
            pairs: [
                {
                    question: 0,
                    choice: ""
                }
            ]

        }]
    });

    useEffect(() => {
        // Pozivamo endpoint na backend-u za dohvaćanje podataka o testu
        fetch('http://localhost:8080/presetTests/3')
            .then(response => response.json())
            .then(data => setDrivers(data))
            .catch(error => console.error('Failed to fetch test list:', error));
    }, []);

    const location = useLocation();
    const answers = location.state?.answers || {};


    const counters : number[] = [0, 0, 0, 0, 0];
    let i : number = 0;

    drivers.results.forEach((res) => {
        const pairs = res.pairs;

        pairs.forEach((pair) => {
            const key = pair.question + "#" + pair.choice;

            counters[i] += parseInt(answers[key]);
        })

        i++;
    })


    let sorted : {
        driver : string,
        strengths : string[],
        weaknesses : string[],
        effectiveness : string[],
        destructiveness : string[],
        changePoints : string[],
        handling : string[],
        advice : {
            basis : string,
            predictedBehaviour : string,
            consequence : string,
            worksWell : string,
            improvement : string,
            managerHelp : string
        }
        ct : number
    }[] = [];

    const indexes = Array.from({ length: counters.length }, (_, index) => index);

    indexes.sort((a,b) => counters[b] - counters[a]);

    indexes.forEach((index) => {
        const res = drivers.results.at(index);

        if(res) {
            sorted.push({
                driver : res.driver,
                strengths : res.strengths,
                weaknesses : res.weaknesses,
                effectiveness : res.effectiveness,
                destructiveness : res.destructiveness,
                changePoints : res.changePoints,
                handling : res.handling,
                advice : {
                    basis : res.advice.basis,
                    predictedBehaviour : res.advice.predictedBehaviour,
                    consequence : res.advice.consequence,
                    worksWell : res.advice.worksWell,
                    improvement : res.advice.improvement,
                    managerHelp : res.advice.managerHelp
                },
                ct : counters[index]
            });
        }

    })


    return(
        <div className="mt-24 min-h-screen font-montserrat flex flex-col items-center">
            <h3 className="text-red-950 text-3xl font-bold p-5 text-center mb-4">Drivers Questionnaire results</h3>

            <div className={'flex flex-col items-center gap-8 pb-10'}>
                {sorted.map((el, key) => (
                    <div key={key} className={'w-1/2 bg-white px-10 pt-5 pb-10 rounded-lg shadow-lg whitespace-pre-line'}>
                        <h1 className={'font-bold text-red-950 text-2xl text-center mb-5'}>{el.driver} ({el.ct})</h1>
                        <span className={'block'}>{el.advice.basis}</span>
                        {"\n"}
                        <span className={'block'}>{el.advice.predictedBehaviour}</span>
                        <span className={'block'}>{el.advice.consequence}</span>
                        {"\n"}
                        <span className={'block'}>{el.advice.worksWell}</span>
                        {"\n"}
                        <span className={'block font-semibold text-red-950'}>{el.advice.improvement}</span>
                        <span className={'block'}>{el.advice.managerHelp}</span>

                    </div>
                ))}
            </div>

        </div>
    );
}

export default DriversResults;
