import React, { useEffect, useState } from "react";
import hrFlag from "../assets/hr.svg";
import gbFlag from "../assets/gb.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";

function Header() {

    const [lang, setLang] = useState(localStorage.getItem('language'));
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const role = sessionStorage.getItem('role');

    const { i18n, t } = useTranslation();
    const changeLanguage = async (lng: string) => {
        await i18n.changeLanguage(lng);
        setLang(lng);
        localStorage.setItem('language', lng);
    };


    const navigate = useNavigate();
    function handleLogout() {
        Cookies.remove("token", { secure: true, sameSite: 'strict' });
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('userId');
        setIsAuthenticated(false);

        navigate("/");
    }

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if(storedLanguage) {
            void i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    useEffect(() => {
        const token = Cookies.get('token');

        if(token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }

    }, []);

    const location = useLocation();

    return (
        <>
            <div className="fixed top-0 w-full z-[2000]">
                <header className="bg-white h-24 flex justify-between shadow-custom">
                    <div className="flex justify-between gap-7 ml-7 items-center">
                        {location.pathname === '/' ? (
                            <>
                                <>
                                    <div className={"flex gap-7 w-1/3"}>
                                        <div className={"flex items-center justify-center"}>
                                            <AnchorLink href="#home">
                                        <span
                                            className="text-red-950 font-semibold text-2xl self-center font-montserrat">{t('HOME')}</span>
                                            </AnchorLink>
                                        </div>

                                        <div className={"flex items-center justify-center"}>
                                            <AnchorLink href="#tests">
                                        <span
                                            className="text-red-950 font-semibold text-2xl self-center font-montserrat">{t('TESTS')}</span>
                                            </AnchorLink>
                                        </div>

                                        <div className={"flex items-center justify-center"}>
                                            <AnchorLink href="#contact">
                                        <span
                                            className="text-red-950 font-semibold text-2xl self-center font-montserrat">{t('CONTACT')}</span>
                                            </AnchorLink>
                                        </div>
                                    </div>

                                    {isAuthenticated && role === 'user' &&
                                        <div>
                                            <Link to={'/addTest'}>
                                            <span
                                                className="text-red-950 font-semibold text-2xl self-center font-montserrat">{t('ADD_NEW_TEST')}</span>
                                            </Link>
                                        </div>
                                    }

                                    {!isAuthenticated &&
                                        <div className={"flex flex-col relative"}>
                                            <div
                                                className={"flex items-center justify-center gap-3 ml-3 cursor-pointer select-none"}
                                                onClick={() => navigate("/login")}>
                                                <span
                                                    className="text-red-950 font-semibold text-2xl self-center font-montserrat">{t('LOGIN.title')}</span>
                                            </div>
                                        </div>
                                    }
                                </>

                            </>
                        ) : (
                            <>
                                {role !== 'admin' &&
                                    <Link to={'/'}>
                                        <span
                                        className="text-red-950 font-semibold text-2xl self-center font-montserrat">{t('HEADER_GO_BACK')}</span>
                                    </Link>
                                }

                            </>
                        )}
                    </div>

                    <div className={"flex items-center justify-center gap-14 mr-7"}>

                        <div className="flex gap-5 items-center">
                            <button onClick={() => changeLanguage('hr')}>
                                <img src={hrFlag} alt="Switch to Croatian"
                                     className={lang === 'en' ? "h-7 saturate-0 opacity-70 hover:scale-110 hover:saturate-100" : "h-7"}/>
                            </button>

                            <button onClick={() => changeLanguage('en')}>
                                <img src={gbFlag} alt="Switch to English"
                                     className={lang === 'hr' ? "h-7 saturate-0 opacity-70 hover:scale-110 hover:saturate-100" : "h-7"}/>
                            </button>
                        </div>

                        {isAuthenticated && location.pathname !== '/addTest' &&
                            <button onClick={handleLogout}>
                                <span className="text-red-950 font-semibold text-2xl self-center font-montserrat">{t('LOGOUT')}</span>
                            </button>
                        }

                    </div>
                </header>
            </div>
        </>

    );
}

export default Header;
