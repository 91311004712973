import React, { ChangeEvent, KeyboardEventHandler } from "react";
import Header from "../components/Header";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next';
import Cookies from "js-cookie";

function AddTest() {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [generalTestData, setGeneralTestData] = useState({
        _id: 0,
        testTitle : "",
        testDescription: "",
        testInstructions: "",
        questionType : "",
        languageTag : "",
        additionalData : {
            numberOfOptions : 0,
            customLabels : [""]
        }
    });

    const [questions, setQuestions] = useState([{
        question : "",
        options : [{
            optionText: "",
            optionValue: 0
        }]
    }]);

    const [gradingMethod, setGradingMethod] = useState({
        calculationMethod : "",
        questionsToGroup : "allQuestions",
        groups : [{
            groupID : 1,
            groupName : "",
            groupQuestions : [] as number[]
        }]
    });

    const [sortingMethod, setSortingMethod] = useState("");

    const [feedback, setFeedback] = useState({
        feedbackShowMethod : "",
        feedbacks : [{
            groupID : 1,
            text : ""
        }]
    });

    const [dropDownState, setDropDownState] = useState(0);


    // Odabir tipa pitanja (rating scale ili radio button)
    const handleTypeOptionChange = (e: any) => {
        const  { value } = e.target;

        if (generalTestData.questionType === 'multiple-option' && !window.confirm('Changing the question type will clear existing question options. Do you want to proceed?')) {
            e.target.value = generalTestData.questionType;
            return;
        }

        setGeneralTestData(prevState => {
            return {
                ...prevState,
                questionType: value,
                additionalData: {
                    numberOfOptions : 0,
                    customLabels : []
                }
            }

        });

        setQuestions(questions.map(q => ({
            ...q,
            options: [],
        })));

        setDropDownState(0);

    }


    const handleDescriptionInstructionsChange = (value: string, inputType: 'description' | 'instructions') => {

        switch (inputType) {
            case "description":
                setGeneralTestData(prevState => {
                    return {
                        ...prevState,
                        testDescription: value
                    }
                });
                break;

            case "instructions":
                setGeneralTestData(prevState => {
                    return {
                        ...prevState,
                        testInstructions: value
                    }
                });
                break;

            default:
                break;
        }

    }


    // Dodavanje inputa za pitanja
    const handleAddInput = () => {
        setQuestions([...questions, {question: "", options: [{optionText: "", optionValue: 0}]}]);

    }

    // Uklanjanje inputa za pitanja
    const handleRemoveInput = (index : number) => {
        const list = [...questions];
        list.splice(index, 1);

        setQuestions(list);
    }

    // Učitavanje teksta pitanja iz inputa
    const handleInputChange = (e : ChangeEvent<HTMLInputElement>, index : number, group : 'labels' | 'questions' | 'testTitle' | 'groupName') => {
        const {value} = e.target;

        switch (group) {
            case "labels":
                setGeneralTestData(prevState => {
                    const newCustomLabels = [...prevState.additionalData.customLabels];
                    newCustomLabels[index] = value;

                    return {
                        ...prevState,
                        additionalData: {
                            ...prevState.additionalData,
                            customLabels: newCustomLabels
                        }
                    }

                });
                break;

            case "questions":
                setQuestions(prevState => {
                    const newQuestions = [...prevState];
                    newQuestions[index].question = value;

                    return newQuestions;

                });
                break;

            case "testTitle":
                setGeneralTestData(prevState => {
                    return {
                        ...prevState,
                        testTitle: value
                    }
                });
                break;

            case "groupName":
                setGradingMethod(prevState => {
                    const newNameGroup = [...prevState.groups];
                    newNameGroup[index].groupName = value;

                    return {
                        ...prevState,
                        groups: newNameGroup
                    }
                });

                break;

            default:
                break;
        }

    }

    // Opcije za radio button pitanja (tekst)
    const handleMultipleChoiceOptionChange = (input : string, questionIndex : number, optionIndex : number, inputType : string) => {

        setQuestions(prevState => {
            const newQuestions = [...prevState];

            if (!newQuestions[questionIndex]) {
                newQuestions[questionIndex] = { question: "", options: [] };
            }

            if (!newQuestions[questionIndex].options) {
                newQuestions[questionIndex].options = [];
            }

            if (!newQuestions[questionIndex].options[optionIndex]) {
                newQuestions[questionIndex].options[optionIndex] = { optionText: "", optionValue: 0 };
            }

            if(inputType === "text") {
                newQuestions[questionIndex].options[optionIndex].optionText = input;
            } else if(inputType === "points") {
                newQuestions[questionIndex].options[optionIndex].optionValue = Number(input);
            }

            return newQuestions;

        });
    }



    // Broj ocjena na skali / opcija kod radio button testova
    const handleChange = (e : any) => {
        const { name, value } = e.target;

        setGeneralTestData({
            ...generalTestData,
            additionalData: {
                ...generalTestData.additionalData,
                [name]: parseInt(value, 10)
            }
        });

        setDropDownState(value);

    }


    // Dodavanje grupa za podjelu pitanja za računanje rezultata
    const handleAddGroups = () => {

        setGradingMethod(prevGradingMethod => ({
            ...prevGradingMethod,
            groups: [
                ...prevGradingMethod.groups,
                {
                    groupID: prevGradingMethod.groups.length + 1,
                    groupName: "",
                    groupQuestions: []
                }
            ]
        }));

        setFeedback(prevState => ({
            ...prevState,
            feedbacks: [
                ...prevState.feedbacks,
                {
                    groupID: prevState.feedbacks.length + 1,
                    text: ""
                }
            ]
        }));
    }

    // Uklanjanje grupa pitanja
    const handleRemoveGroups = (groupIndex : number) => {
        setGradingMethod(prevState => {
            const newGroups = prevState.groups.filter((_, index) => index !== groupIndex);

            return {
                ...prevState,
                groups: newGroups
            };
        });

        setFeedback(prevState => ({
            ...prevState,
            feedbacks: prevState.feedbacks.filter((_, index) => index !== groupIndex)
        }));
    }


    // Grupiranje svih / određenih pitanja
    const handleGroupingOption = (e : any) => {
        const {value} = e.target;

        if (gradingMethod.questionsToGroup === 'groupQuestions' && !window.confirm('Changing the grouping method will clear existing feedback for each group. Do you want to proceed?')) {
            e.target.value = gradingMethod.questionsToGroup;
            return;
        }

        setGradingMethod({...gradingMethod, questionsToGroup: value});

        setFeedback({...feedback, feedbacks: [{groupID: 0, text: ""}]});

    }


    // Kod grupiranja
    const handleCheckboxChange = (groupIndex: number, e : any) => {
        const questionId = Number(e.target.value);

        setGradingMethod(prevState => {
            const newGroups = prevState.groups.map((group, index) => {
                if (index === groupIndex) {
                    const newGroupQuestions = e.target.checked
                        ? [...group.groupQuestions, questionId]
                        : group.groupQuestions.filter(id => id !== questionId);
                    return {
                        ...group,
                        groupQuestions: newGroupQuestions
                    };
                }
                return group;
            });

            return {
                ...prevState,
                groups: newGroups
            };
        });

    }

    const handleGradingCalculationMethod = (calculationMethod : string) => {
        setGradingMethod(prevState => {
            return {
                ...prevState,
                calculationMethod: calculationMethod
            }
        });

    }

    // Za sortiranje
    const handleSortingOption = (sortMethod : string) => {
        setSortingMethod(sortMethod);

    }

    const handleFeedbackChange = (index : number, value : string) => {
        if(index === -1) {
            setFeedback(prevState => ({
                ...prevState,
                feedbackShowMethod: value
            }));
        } else {
            setFeedback(prevState => ({
                ...prevState,
                feedbacks: prevState.feedbacks.map((item, idx) => {
                    if (idx === index) {
                        return { ...item, text: value };
                    }
                    return item;
                })
            }));
        }

    };


    // Za sprječavanje submit-a na enter
    const handleEnterKey: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const ratingOptions = [
        {id: "number-of-ratings-2", value: 2},
        {id: "number-of-ratings-3", value: 3},
        {id: "number-of-ratings-4", value: 4},
        {id: "number-of-ratings-5", value: 5},
        {id: "number-of-ratings-6", value: 6},
        {id: "number-of-ratings-7", value: 7},
        {id: "number-of-ratings-8", value: 8},
        {id: "number-of-ratings-9", value: 9},
        {id: "number-of-ratings-10", value: 10}
    ];

    const myRef = React.useRef<HTMLInputElement>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)  => {
        e.preventDefault();

        const testData = {
            ...generalTestData,
            questions,
            gradingMethod,
            sortingMethod,
            feedback
        };

        try {
            const response = await fetch('http://localhost:8080/tests', {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'x-auth-token' : Cookies.get('token') || ""
                },
                body: JSON.stringify(testData)
            });

            if(response.ok) {
                toast.success("Test data saved successfully.");
                navigate("/");
            } else {
                const errMessage = await response.text();
                toast.error(errMessage);
            }
        } catch (err) {
            toast.error('Failed to save test data');
        }
    }

    const getOrdinalSuffix = (index : Number) => {
        if (index === 0) return 'st';
        if (index === 1) return 'nd';
        if (index === 2) return 'rd';
        return 'th';
    };


    return(
        <>
            <div className="bg-teal-100 mt-24 min-h-screen w-[100%] font-montserrat">
                <Header/>
                <div className="p-5 pt-10 text-center">
                    <h1 className="text-3xl font-semibold text-red-950 mb-2">{t('ADD_NEW_TEST_PAGE.addNewTestTitle')}</h1>
                    <p className={"text-red-900 font-semibold"}>{t('ADD_NEW_TEST_PAGE.requiredFieldsNote')}</p>
                </div>

                <form className={"newTest flex flex-col mx-20 my-auto"} onSubmit={handleSubmit}>
                    <div>
                        <h2 className={"font-bold text-red-950 mb-3"}>{t('ADD_NEW_TEST_PAGE.testTitle')}</h2>

                        <input type={"text"} name={"title"} onChange={(e) => handleInputChange(e, 0, "testTitle")}
                               onKeyDown={handleEnterKey} className={"rounded w-1/2 px-2 py-1 h-10"} required/>
                    </div>

                    <div className={"flex flex-col gap-5 py-2 mb-2 mt-8"}>
                        <span className={"font-bold text-red-950"}>{t('ADD_NEW_TEST_PAGE.testLanguage.title')}:</span>
                        <div className={"flex gap-3"}>
                            <button type={"button"} onClick={() => setGeneralTestData({...generalTestData, languageTag : "en"})}
                                    className={generalTestData.languageTag === "en" ? "bg-gray-50 px-4 py-1 rounded-xl shadow-inner shadow-teal-800" : "bg-gray-50 px-4 py-1 shadow-sm shadow-teal-700 rounded-xl active:shadow-inner active:shadow-gray-300"}>
                                {t('ADD_NEW_TEST_PAGE.testLanguage.en')}
                            </button>
                            <button type={"button"} onClick={() => setGeneralTestData({...generalTestData, languageTag : "hr"})}
                                    className={generalTestData.languageTag === "hr" ? "bg-gray-50 px-4 py-1 rounded-xl shadow-inner shadow-teal-800" : "bg-gray-50 px-4 py-1 shadow-sm shadow-teal-700 rounded-xl active:shadow-inner active:shadow-gray-300"}>
                                {t('ADD_NEW_TEST_PAGE.testLanguage.hr')}
                            </button>
                        </div>
                    </div>

                    <div className={"mt-5"}>
                        <h2 className={"font-bold text-red-950 mb-3"}>{t('ADD_NEW_TEST_PAGE.testDescription')}</h2>
                        <p>{t('ADD_NEW_TEST_PAGE.testDescriptionNote')}</p>

                        <textarea name="feedback" value={generalTestData.testDescription} cols={50} rows={10}
                                  onChange={(e) => handleDescriptionInstructionsChange(e.target.value, "description")}
                                  className={"rounded max-h-[350px] w-1/2 p-3 mt-5"}></textarea>
                    </div>

                    <div className={"mt-5"}>
                        <h2 className={"font-bold text-red-950 mb-3"}>{t('ADD_NEW_TEST_PAGE.testInstructions')}</h2>
                        <p>{t('ADD_NEW_TEST_PAGE.testInstructionsNote')}</p>

                        <textarea name="feedback" value={generalTestData.testInstructions} cols={50} rows={10}
                                  onChange={(e) => handleDescriptionInstructionsChange(e.target.value, "instructions")}
                                  className={"rounded max-h-[350px] w-1/2 p-3 mt-5"}></textarea>
                    </div>

                    <div ref={myRef} className={"mt-5"}>
                        <h2 className={"font-bold text-red-950 mb-3"}>{t('ADD_NEW_TEST_PAGE.questionType')}</h2>
                        <p>{t('ADD_NEW_TEST_PAGE.questionTypeNote')}</p>

                        <div className={"flex flex-col gap-3 py-4"}>

                            <select name={"questionType"} id={"rating-scale"}
                                    onChange={handleTypeOptionChange} className={"w-40 h-10 p-1 rounded"} required>
                                <option value="">...</option>
                                <option value="rating-scale">{t('ADD_NEW_TEST_PAGE.ratingScale')}</option>
                                <option value="multiple-option">{t('ADD_NEW_TEST_PAGE.radioButton')}</option>
                            </select>

                            {generalTestData.questionType === "rating-scale" &&
                                <div className={"bg-white w-1/2 shadow-lg p-5 flex flex-col gap-10"}>

                                    <div>
                                        <span
                                            className={"mr-10 font-semibold text-red-900"}>{t('ADD_NEW_TEST_PAGE.numberOfLabels')}</span>
                                        <select name={"numberOfOptions"}
                                                id={"number-of-ratings"} onChange={handleChange}
                                                className={"border-2 border-red-800 rounded"} value={dropDownState}
                                                required>
                                            <option value={0}>...</option>
                                            {ratingOptions.map((option, index) => (
                                                <option key={index} value={option.value} id={option.id}
                                                        className={"text-red-800 font-semibold"}>{option.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className={"flex flex-col gap-3"}>
                                        <div className={"mb-2"}>
                                            <span
                                                className={"mt-5 font-semibold text-red-900"}>{t('ADD_NEW_TEST_PAGE.customLabels')}</span>
                                            <p>{t('ADD_NEW_TEST_PAGE.customLabelsNote')}</p>
                                        </div>

                                        <div className={"flex flex-col gap-2"}>
                                            {[...Array(generalTestData.additionalData.numberOfOptions)].map((_, index) => {

                                                const suffix = getOrdinalSuffix(index);

                                                return (
                                                    <div key={index}>
                                                        <label htmlFor={"label"} className={"inline-block w-1/4 ml-5"}>
                                                            {index + 1}
                                                            {t(`ADD_NEW_TEST_PAGE.label.${suffix}`)}
                                                        </label>
                                                        <input type={"text"} id={"label"}
                                                               onChange={(e) => handleInputChange(e, index, 'labels')}
                                                               onKeyDown={handleEnterKey}
                                                               className={"border border-red-950 rounded w-2/3 px-2 py-1"}/>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    </div>

                                </div>
                            }


                            {generalTestData.questionType === "multiple-option" &&
                                <div className={"bg-white min-h-[150px] w-1/2 shadow-lg p-5 flex flex-col gap-5"}>

                                    <span>{t('ADD_NEW_TEST_PAGE.chooseOptionsNote')}</span>

                                    <div>
                                        <span
                                            className={"mr-10 ml-5 text-red-900 font-semibold"}>{t('ADD_NEW_TEST_PAGE.numberOfOptions')}</span>
                                        <select name={"numberOfOptions"}
                                                id={"number-of-options"} onChange={handleChange}
                                                className={"border-2 border-red-800 rounded"}
                                                value={dropDownState} required>
                                            <option value={0}>...</option>
                                            {ratingOptions.map((option, index) => (
                                                <option key={index} value={option.value} id={option.id}
                                                        className={"text-red-800 font-semibold"}>{option.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <hr className={"border-t-[2px] border-t-red-950"}/>

                    <div className={"form-field my-5"}>
                        <h2 className={"font-bold text-red-950 mb-3"}>{t('ADD_NEW_TEST_PAGE.questionTitle')}</h2>
                        <p>{t('ADD_NEW_TEST_PAGE.questionTitleNote-beforeBR')} <br/>
                            {t('ADD_NEW_TEST_PAGE.questionTitleNote-afterBR')} <br/>
                            {generalTestData.questionType === 'multiple-option' && <>{t('ADD_NEW_TEST_PAGE.multiple-option-note.before-br')}
                                <br/>
                                {t('ADD_NEW_TEST_PAGE.multiple-option-note.after-br')}
                            </>}

                        </p>

                        {questions.map((singleInput, index) => (
                            <div key={index} className={"inputs"}>
                                <div className={"first-division flex flex-col"}>
                                    <div className={"flex items-center"}>
                                        <input name={"service"} type="text" id={"service"} required
                                               value={singleInput.question}
                                               onChange={(e) => handleInputChange(e, index, 'questions')}
                                               onKeyDown={handleEnterKey}
                                               className={questions.length === 1 ? "h-10 w-[50%] p-2 rounded block my-5" : "h-10 w-[50%] p-2 rounded mt-3 mb-5 mr-5"}/>

                                        {questions.length > 1 &&
                                            <button type={"button"} onClick={() => handleRemoveInput(index)}
                                                    className={"remove-btn bg-red-900 text-red-50 text-sm font-bold p-3  rounded-[5px]"}>
                                                {t('ADD_NEW_TEST_PAGE.removeQuestion')}
                                            </button>
                                        }
                                    </div>


                                    {generalTestData.questionType === "multiple-option" &&
                                        <div className={"flex flex-col w-2/5 ml-10 gap-5 mb-5"}>
                                            {[...Array(generalTestData.additionalData.numberOfOptions)].map((_, optionIndex) => (
                                                <div key={optionIndex} className={"flex gap-5"}>
                                                    <input key={optionIndex} type="text"
                                                           value={questions[index]?.options[optionIndex]?.optionText || ""}
                                                           onChange={(e) => handleMultipleChoiceOptionChange(e.target.value, index, optionIndex, "text")}
                                                           onKeyDown={handleEnterKey}
                                                           className={"h-9 rounded p-2 w-full"}
                                                           required/>

                                                    <input type="number" name="points"
                                                           value={questions[index]?.options[optionIndex]?.optionValue || ""}
                                                           min={0}
                                                           onChange={(e) => handleMultipleChoiceOptionChange(e.target.value, index, optionIndex, "points")}
                                                           onKeyDown={handleEnterKey} className={"h-9 rounded p-2 w-20"}
                                                           required/>
                                                </div>
                                            ))}
                                        </div>
                                    }


                                </div>

                                <div className={"second-division"}>
                                    {questions.length - 1 === index &&
                                        <button type={"button"} onClick={handleAddInput}
                                                className={"add-btn w-fit border-2 border-red-900 bg-red-900 text-red-50 font-bold py-2 px-4 rounded-[5px] cursor-pointer text-sm"}>
                                            {t('ADD_NEW_TEST_PAGE.addAnotherQuestion')}
                                        </button>
                                    }
                                </div>

                            </div>
                        ))}

                    </div>

                    <hr className={"border-t-[2px] border-t-red-950"}/>

                    <div className={"flex flex-col my-5 gap-2 w-1/2"}>
                        <span className={"font-bold text-red-950"}>{t('ADD_NEW_TEST_PAGE.howAnswersGraded')}</span>
                        <p>{t('ADD_NEW_TEST_PAGE.howAnswersGradedNote')}</p>

                        <div className={"bg-white min-h-[500px] p-5 shadow-lg w-full"}>
                            <span className={"mr-10"}>{t('ADD_NEW_TEST_PAGE.resultCalculationMethod')}</span>
                            <select name="calculation" id="calc" className={"border-2 border-red-800 rounded"}
                                    onChange={(e) => handleGradingCalculationMethod(e.target.value)} required>
                                <option value="">...</option>
                                <option value="mean">{t('ADD_NEW_TEST_PAGE.meanCalculation')}</option>
                                <option value="sum">{t('ADD_NEW_TEST_PAGE.sumCalculation')}</option>
                            </select>


                            <div className={"mt-5 flex flex-col gap-3 mb-10"}>
                                <span className={"mb-2"}>
                                    <Trans i18nKey={"ADD_NEW_TEST_PAGE.defaultGroupingNote"}/>
                                    </span>
                                <label htmlFor={"calculation"} className={"flex items-center"}>
                                    <input type={"radio"} name={"calculationRange"} id={"all-questions"}
                                           value={"allQuestions"} onChange={(e) => handleGroupingOption(e)}
                                           className="cursor-pointer outline-none transition-all duration-200 ease-in min-w-[30px] w-[30px] h-[30px] mr-4 appearance-none border-none bg-gray-200 hover:bg-red-900 focus:bg-red-950 checked:bg-red-950 rounded-xl"/>
                                    {t('ADD_NEW_TEST_PAGE.allQuestionsGrouping')}
                                </label>

                                <label htmlFor={"calculation"} className={"flex items-center"}>
                                    <input type={"radio"} name={"calculationRange"} id={"group-questions"}
                                           value={"groupQuestions"} onChange={(e) => handleGroupingOption(e)}
                                           className="cursor-pointer outline-none transition-all duration-200 ease-in min-w-[30px] w-[30px] h-[30px] mr-4 appearance-none border-none bg-gray-200 hover:bg-red-900 focus:bg-red-950 checked:bg-red-950 rounded-xl"/>
                                    {t('ADD_NEW_TEST_PAGE.groupQuestionsGrouping')}
                                </label>
                            </div>

                            {gradingMethod.questionsToGroup === "groupQuestions" &&
                                <div className={"w-full"}>
                                    <span className={"font-bold text-red-950"}>{t('ADD_NEW_TEST_PAGE.groups')}</span>
                                    {gradingMethod.groups.map((group, index) => (

                                        <div key={index} className={"mt-2"}>
                                            <div
                                                className={"flex flex-col justify-center border border-red-950 rounded px-3 pt-2"}>

                                                <div className={"flex flex-col gap-3 mb-5"}>
                                                    <div className={"flex items-center mb-5 w-11/12"}>
                                                        <label
                                                            className={"inline-block w-[150px]"}>{t('ADD_NEW_TEST_PAGE.groupTitle')}</label>
                                                        <input type={"text"} name="groupName"
                                                               value={gradingMethod.groups[index].groupName}
                                                               onChange={(e) => handleInputChange(e, index, "groupName")}
                                                               className={"border border-red-950 rounded ml-5 p-1 w-5/6"}
                                                               required/>
                                                    </div>

                                                    <div className={"flex justify-evenly flex-wrap"}>
                                                        {questions.map((_, idx) => (
                                                            <label key={idx} className={"flex flex-col items-center"}>
                                                                <input type="checkbox" value={idx + 1}
                                                                       onChange={(e) => handleCheckboxChange(index, e)}
                                                                       checked={group.groupQuestions.includes(idx + 1)}/>
                                                                {idx + 1}
                                                            </label>

                                                        ))}
                                                    </div>
                                                </div>

                                                {gradingMethod.groups.length > 1 &&
                                                    <button type={"button"} onClick={() => handleRemoveGroups(index)}
                                                            className={"remove-btn bg-red-900 text-red-50 text-sm font-bold p-3  rounded-t-[5px] w-40 self-center"}>
                                                        {t('ADD_NEW_TEST_PAGE.removeGroup')}
                                                    </button>
                                                }

                                            </div>
                                        </div>

                                    ))}

                                    <div className={"mt-5"}>
                                        <button type={"button"} onClick={() => handleAddGroups()}
                                                className={"add-btn w-fit border-2 border-red-900 bg-red-900 text-red-50 font-bold py-2 px-4 mb-7 rounded-[5px] cursor-pointer text-sm"}>
                                            {t('ADD_NEW_TEST_PAGE.addAnotherGroup')}
                                        </button>
                                    </div>


                                </div>
                            }

                        </div>

                    </div>

                    {gradingMethod.questionsToGroup === "groupQuestions" &&
                        <>
                            <div className={"flex flex-col my-5 gap-2 w-1/2"}>
                                <h2 className={"font-bold text-red-950"}>{t('ADD_NEW_TEST_PAGE.resultSortingTitle')}</h2>
                                <p>{t('ADD_NEW_TEST_PAGE.resultSortingNote')}</p>

                                <div className={"flex flex-col gap-2 bg-white min-h-[250px] p-5 shadow-lg"}>
                                    <span>{t('ADD_NEW_TEST_PAGE.defaultOrderNote')}</span>
                                    <span
                                        className={"mb-2"}>{t('ADD_NEW_TEST_PAGE.alternativeOrderNote')}</span>

                                    <label htmlFor={"resultSort"} className={"flex items-center"}>
                                        <input type={"radio"} name={"resultSort"}
                                               value={"alphabet"} onChange={(e) => handleSortingOption(e.target.value)}
                                               className="cursor-pointer outline-none transition-all duration-200 ease-in min-w-[30px] w-[30px] h-[30px] mr-4 appearance-none border-none bg-gray-200 hover:bg-red-900 focus:bg-red-950 checked:bg-red-950 rounded-xl"/>
                                        {t('ADD_NEW_TEST_PAGE.alphabeticalOrder')}
                                    </label>

                                    <label htmlFor={"resultSort"} className={"flex items-center"}>
                                        <input type={"radio"} name={"resultSort"}
                                               value={"noOfPoints"}
                                               onChange={(e) => handleSortingOption(e.target.value)}
                                               className="cursor-pointer outline-none transition-all duration-200 ease-in min-w-[30px] w-[30px] h-[30px] mr-4 appearance-none border-none bg-gray-200 hover:bg-red-900 focus:bg-red-950 checked:bg-red-950 rounded-xl"/>
                                        {t('ADD_NEW_TEST_PAGE.pointsOrder')}
                                    </label>
                                </div>

                            </div>
                        </>
                    }


                    <hr className={"border-t-[2px] border-t-red-950"}/>

                    <div className={"flex flex-col my-5 gap-2 w-1/2"}>
                        <h2 className={"font-bold text-red-950"}>{t('ADD_NEW_TEST_PAGE.feedbackTitle')}</h2>
                        <p>{t('ADD_NEW_TEST_PAGE.feedbackNote')}</p>

                        <div className={"bg-white min-h-[500px] p-5 shadow-lg"}>

                            <div className={"mb-5"}>
                                <span>{t('ADD_NEW_TEST_PAGE.feedbackShowMethod')}</span>
                                <select name="feedbackShowMethod" value={feedback.feedbackShowMethod}
                                        onChange={(e) => handleFeedbackChange(-1, e.target.value)}
                                        className={"border-2 border-red-900 rounded ml-10"} required>
                                    <option value="">...</option>
                                    <option value="immediately">{t('ADD_NEW_TEST_PAGE.feedbackImmediately')}</option>
                                    <option value="later">{t('ADD_NEW_TEST_PAGE.feedbackLater')}</option>
                                </select>
                            </div>

                            {gradingMethod.questionsToGroup === "allQuestions" &&
                                <div className={"flex flex-col gap-3"}>
                                    <span>{t('ADD_NEW_TEST_PAGE.defaultFeedbackNote')}</span>

                                    <textarea name="feedback" value={feedback.feedbacks[0].text} cols={50} rows={10}
                                              onChange={(e) => handleFeedbackChange(0, e.target.value)}
                                              className={"border-2 border-red-950 rounded max-h-[350px] p-3"}></textarea>

                                </div>
                            }

                            {gradingMethod.questionsToGroup === "groupQuestions" &&
                                <div className={"flex flex-col gap-5"}>
                                    <span>{t('ADD_NEW_TEST_PAGE.defaultFeedbackNote')}</span>
                                    {gradingMethod.groups.map((_, idx) => (
                                        <div key={idx} className={"flex flex-col gap-1"}>
                                            <span
                                                className={"font-semibold text-red-950"}>{t('ADD_NEW_TEST_PAGE.forGroup')} {idx + 1}</span>
                                            <textarea name="feedback" value={feedback.feedbacks[idx]?.text || ""}
                                                      cols={50}
                                                      rows={10}
                                                      onChange={(e) => handleFeedbackChange(idx, e.target.value)}
                                                      className={"border-2 border-red-950 rounded max-h-[350px] min-h-[100px] p-3"}></textarea>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>

                    </div>

                    <div>
                        <span>{t('ADD_NEW_TEST_PAGE.submitNote')}</span>
                    </div>


                    <div className={"self-center"}>
                        <button type={"submit"}
                                className={"w-32 bg-red-900 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"}>{t('submitButton')}
                        </button>

                    </div>
                </form>

            </div>

            <ToastContainer position="bottom-right"
                            autoClose={3000}
                            limit={2}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"/>
        </>

    );

}

export default AddTest;
