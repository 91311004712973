import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";


type Option = {
    optionText: string;
    optionValue: number;
}
type Question = {
    question: string;
    options: Option[];
}

type AdditionalData = {
    customLabels: string[];
    numberOfOptions: number;
    style: string;
}

type Group = {
    groupID: number;
    groupName: string;
    groupQuestions: number[];
}

type GradingMethod = {
    calculationMethod: string;
    questionsToGroup: string;
    groups: Group[];
}

type Feedback = {
    groupID: number;
    text: string;
}

type CompleteFeedback = {
    feedbackShowMethod: string;
    feedbacks: Feedback[];
}
type testData = {
    testTitle: string;
    testDescription: string;
    testInstructions: string;
    questionType: string;
    additionalData: AdditionalData;
    questions: Question[];
    gradingMethod: GradingMethod;
    sortingMethod: string;
    feedback: CompleteFeedback;
}

interface GroupData {
    groupID: number;
    groupName: string;
    score: number;
}

const CustomTestResults: React.FC = () => {

    const location = useLocation();
    const {answers, data} = location.state as { answers : Record<string, any>, data : testData }

    const [allQScore, setAllQScore] = useState(0);

    const [groupsData, setGroupsData] = useState<GroupData[]>([]);
    const [sortedData, setSortedData] = useState<GroupData[]>([]);

    const [showFeedback, setShowFeedback] = useState(false);
    const [showGroupFeedback, setShowGroupFeedback] = useState([false]);


    const toggleShowFeedback = (idx: number, type : "group" | "all") => {

        switch (type) {
            case "group":
                setShowGroupFeedback(prevState => {
                    const newGroupFeedback = [...prevState];

                    newGroupFeedback[idx] = !prevState[idx];

                    return newGroupFeedback;
                })
                break;
            case "all":
                setShowFeedback(prevState => !prevState);
                break;
            default:
                break;
        }


    }


    const grade = () => {
        if(data.gradingMethod.questionsToGroup === 'allQuestions') {

            if(data.gradingMethod.calculationMethod === 'sum') {

                const sum =  Object.keys(answers).reduce((accumulator, key) => {
                    return accumulator + parseInt(answers[key], 10);
                }, 0);

                setAllQScore(sum);

            } else if(data.gradingMethod.calculationMethod === 'mean') {

                const sum = Object.keys(answers).reduce((accumulator, key) => {
                    return accumulator + parseInt(answers[key], 10);
                }, 0);

                const count = Object.keys(answers).length;

                const mean = sum / count;

                setAllQScore(mean);

            }

        } else if(data.gradingMethod.questionsToGroup === 'groupQuestions') {

            if(data.gradingMethod.calculationMethod === 'sum') {

                const groupsData = data.gradingMethod.groups.map(group => {
                    const sum = group.groupQuestions.reduce((accumulator, questionNumber) => {
                        return accumulator + (answers[questionNumber] ? parseInt(answers[questionNumber], 10) : 0);
                    }, 0);
                    return { groupID: group.groupID, groupName: group.groupName, score: sum };
                });

                setGroupsData(groupsData);

            } else if(data.gradingMethod.calculationMethod === 'mean') {

                const groupsData = data.gradingMethod.groups.map(group => {
                    const validAnswers = group.groupQuestions.filter(questionNumber => answers[questionNumber]);
                    const sum = validAnswers.reduce((accumulator, questionNumber) => {
                        return accumulator + parseInt(answers[questionNumber], 10);
                    }, 0);
                    const mean = validAnswers.length ? sum / validAnswers.length : 0;
                    return { groupID: group.groupID, groupName: group.groupName, score: mean };
                });

                setGroupsData(groupsData);

            }

        }

    }

    const sortData = () => {
        if(data.sortingMethod === 'alphabet') {
            setSortedData([...groupsData].sort((a, b) => a.groupName.localeCompare(b.groupName)));
        } else if(data.sortingMethod === 'noOfPoints') {
            setSortedData([...groupsData].sort((a, b) => b.score - a.score));
        } else if(data.sortingMethod === '') {
            setSortedData(groupsData);
        }
    }

    useEffect(() => {
        grade();
    }, [data, answers]);


    useEffect(() => {
        sortData();
    }, [groupsData, data.sortingMethod]);


    return(
        <div className={"flex flex-col items-center"}>
            <div className={"flex flex-col items-center"}>
                <h3 className="text-red-950 text-3xl font-bold p-5 text-center">{data.testTitle} results</h3>
                <span className={"text-lg"}>Here are your test results</span>
            </div>

            <div className={"mt-10 w-1/2 min-h-[500px] bg-white shadow-lg p-10"}>
                {data.gradingMethod.questionsToGroup === 'groupQuestions' &&
                    <div className={"flex flex-col gap-8"}>
                        {sortedData.map((group, idx) => (
                            <div key={idx}>
                                <div className={"flex gap-5 items-center mb-2"}>
                                    <span className={"text-2xl font-bold text-red-950"}>{group.groupName}:</span>
                                    <span className={"text-xl"}>{group.score}</span>
                                </div>

                                {data.feedback.feedbackShowMethod === "immediately" ?
                                    <div>
                                        {data.feedback.feedbacks.some(feedback => feedback.groupID === group.groupID) && (
                                            <span>
                                            {data.feedback.feedbacks.find(feedback => feedback.groupID === group.groupID)?.text}
                                        </span>
                                        )}
                                    </div>
                                    :
                                    <>
                                        {showGroupFeedback[idx] &&
                                            <div>
                                                {data.feedback.feedbacks.some(feedback => feedback.groupID === group.groupID) && (
                                                    <span>
                                            {data.feedback.feedbacks.find(feedback => feedback.groupID === group.groupID)?.text}
                                        </span>
                                                )}
                                            </div>
                                        }
                                        <button
                                            className={"w-40 bg-red-900 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"}
                                            onClick={() => toggleShowFeedback(idx, "group")}>
                                            {showGroupFeedback[idx] ? "Hide feedback" : "Show feedback"}
                                        </button>

                                    </>

                                }
                            </div>

                        ))
                        }
                    </div>

                }

                {data.gradingMethod.questionsToGroup === 'allQuestions' &&
                    <>
                        <div className={"flex gap-5 items-center mb-2"}>
                            <span className={"text-2xl font-bold text-red-950"}>Your score:</span>
                            <span className={"text-xl"}>{allQScore}</span>
                        </div>

                        {data.feedback.feedbackShowMethod === "immediately" ?
                            <div>
                                {data.feedback.feedbacks[0].text}
                            </div>
                            :
                            <>
                                {showFeedback &&
                                    <div>
                                        {data.feedback.feedbacks[0].text}
                                    </div>
                                }
                                <button className={"w-40 bg-red-900 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-sm"} onClick={() => toggleShowFeedback(-1, "all")}>
                                    {showFeedback ? "Hide feedback" : "Show feedback"}
                                </button>

                            </>

                        }
                    </>
                }

            </div>

        </div>
    );
}

export default CustomTestResults;
