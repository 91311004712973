import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Test from "./pages/Test";
import TestResults from "./pages/TestResults";
import AddTest from "./pages/AddTest";
import Login from "./pages/Login";
import Unauthorized from "./pages/Unauthorized";
import AdminDashboard from "./pages/AdminDashboard";
import ProtectedRoute from "./auth/ProtectedRoute";


const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};
function App() {
  return (
      <Router>
          <ScrollToTop />
        <Routes>
            <Route path="login" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            <Route path="/" element={
                <ProtectedRoute allowUnauthenticated={true}>
                    <Home />
                </ProtectedRoute>
            } />
            <Route path="/tests/:test" element={
                <ProtectedRoute allowUnauthenticated={true}>
                    <Test />
                </ProtectedRoute>
            } />
            <Route path="/testResults/:result" element={
                <ProtectedRoute allowUnauthenticated={true}>
                    <TestResults />
                </ProtectedRoute>
            } />
            <Route path="/addTest" element={
                <ProtectedRoute requiredRole={"user"} allowUnauthenticated={false}>
                    <AddTest />
                </ProtectedRoute>
                } />
            <Route path="/adminDashboard" element={
                <ProtectedRoute requiredRole={"admin"} allowUnauthenticated={false}>
                    <AdminDashboard />
                </ProtectedRoute>
                } />
        </Routes>

      </Router>
  );
}

export default App;
