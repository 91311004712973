import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";


function ValuesTestResults() {

    const [valuesRes, setValuesRes] = useState({
        name: "",
        description: "",
        questions: [{
            questionNo: 0,
            text: ""
        }],
        results: [{
            id: "",
            header: "",
            text: ""
        }]
    });

    useEffect(() => {
        // Pozivamo endpoint na backend-u za dohvaćanje podataka o testu
        fetch('http://localhost:8080/presetTests/2')
            .then(response => response.json())
            .then(data => setValuesRes(data))
            .catch(error => console.error('Failed to fetch test list:', error));
    }, []);


    const location = useLocation();

    const answers = location.state?.answers || {};

    const counters: number[] = [];

    for(let i = 0; i < 14; i++) {
        counters[i] = 0;
    }


    const entries = Object.entries(answers);

    /*Sortiranje po broju pitanja*/
    entries.sort((a,b) => parseInt(a[0]) - parseInt(b[0]));
    function incrementCt(idx: number, val: number) {
        if(idx >= 0 && idx < counters.length) {
            counters[idx] += val;
        }
    }

    // Zbrajanje rezultata
    let i = 0;
    entries.forEach((entry) => {
        let valToAdd = 0;
        if(typeof entry[1] === "string") {
            valToAdd = parseInt(entry[1]);
        }

        /*Zbrajaju se rezultati za svako 15. pitanje*/
        incrementCt(i, valToAdd);

        if(i === counters.length - 1) {
            i = 0;
        } else {
            i++;
        }

    });


    // Inicijalizacija polja objekata za sortirane rezultate
    let sorted : {id: string, ct: number, header: string, text: string}[] = [];

    const indexes = Array.from({ length: counters.length }, (_, index) => index);

    indexes.sort((a,b) => counters[b] - counters[a]);

    indexes.forEach((index) => {
        const res = valuesRes.results.at(index);

        if(res) {
            sorted.push({
                id: res.id,
                ct: counters[index],
                header: res.header,
                text: res.text
            });
        }

    })

    return (
        <div className="mt-24 min-h-screen font-montserrat flex flex-col items-center">
            <h3 className="text-red-950 text-3xl font-bold p-5 text-center">Values Inventory results</h3>

            <div className="p-10 grid grid-cols-2 bg-white w-3/4 mb-10">
                {sorted.map((el, index) => (
                    <div key={index} className="p-10">
                        {/*<span className="font-bold text-2xl">{res.id} : </span>*/}
                        <h3 className="font-bold text-2xl">{el.id}: {el.header} ({el.ct})</h3>
                        <p>{el.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ValuesTestResults;
