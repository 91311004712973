import React, {useState} from "react";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import Cookies from "js-cookie";
import ShowPassword from "../assets/show-pass.png";
import HidePassword from "../assets/hide-pass.png";

type resData = {
    msg: string,
    tkn: string,
    userId: string
}
function Login() {

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [err, serErr] = useState<boolean | null>(null);
    const [passwordHidden, setPasswordHidden] = useState<boolean>(true);

    const navigate = useNavigate();

    const { t } = useTranslation();

    // HANDLERI
    // Funkcija za promjenu username-a
    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    }

    // Funkcija za promjenu password-a
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const toggleShowPass = () => {
        setPasswordHidden(prevState => !prevState);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const res = await axios.post<resData>("http://localhost:8080/login", { username, password });
            const token = res.data.tkn;
            const decoded = jwtDecode<{
                user: {
                    id: string
                },
                role: string
            }>(token);

            Cookies.set("token", token, {
                expires: 1,
                secure: true,
                sameSite: 'strict'
            });

            sessionStorage.setItem('role', decoded.role);
            sessionStorage.setItem('userId', res.data.userId);

            if(decoded.role === 'admin') {
                navigate("/adminDashboard");
            } else if(decoded.role === 'user') {
                navigate("/");
            }

        } catch (error) {
            serErr(true);
        }

    }

    return(
        <>
            <Header />
            <div className={"bg-teal-100 w-screen h-screen flex items-center justify-center"}>

                <div className={"bg-white rounded-2xl shadow-md w-1/3 h-1/2 flex flex-col items-center"}>

                    <div className={"p-10"}>
                        <h1 className={"text-3xl font-semibold text-red-900"}>{t('LOGIN.title')}</h1>
                    </div>

                    {err && <span className={"text-red-700 font-semibold"}>{t('LOGIN.invalidCredentials')}</span>}

                    <form onSubmit={handleSubmit} method={"POST"} className={"flex flex-col items-center"}>
                        <div className={"w-full flex flex-col gap-5 my-10"}>
                            <div className={"flex justify-center items-center"}>
                                <label
                                    className={"text-lg font-semibold inline-block w-1/3 text-right pr-5 text-red-950"}>{t('LOGIN.username')}:</label>
                                <input type="text" name={"username"}
                                       className={"h-11 px-2 border-2 border-red-950 rounded w-1/2"}
                                       onChange={handleUsernameChange} required/>
                            </div>

                            <div className={"flex justify-center items-center"}>
                                <label
                                    className={"text-lg font-semibold inline-block w-1/3 text-right pr-5 text-red-950"}>{t('LOGIN.password')}:</label>
                                <div className={"w-1/2 relative flex items-center"}>
                                    <input type={passwordHidden ? "password" : "text"} name={"password"}
                                           className={"w-full h-11 px-2 border-2 border-red-950 rounded"}
                                           onChange={handlePasswordChange} required/>
                                    <img src={passwordHidden ? ShowPassword : HidePassword} alt="show password icon" className={"absolute end-0 cursor-pointer mr-2"} onClick={toggleShowPass} />
                                </div>
                            </div>
                        </div>

                        <div>
                            <button type={"submit"}
                                    className={"w-32 bg-red-900 text-red-50 font-bold p-2 mt-5 mb-10 rounded-[5px] cursor-pointer text-md"}>{t('LOGIN.submit')}
                            </button>
                        </div>
                    </form>


                </div>

            </div>
        </>

    );
}

export default Login;
