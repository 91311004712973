import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function Unauthorized() {

    const {t} = useTranslation();
    const navigate = useNavigate();

    return(
        <div className={"w-screen h-screen bg-teal-100 flex justify-center items-center"}>
            <div className={"bg-white w-1/3 h-1/3 rounded-xl shadow-md flex flex-col items-center"}>
                <h1 className={"mt-10 font-bold text-red-950 text-2xl"}>{t('UNAUTHORIZED.title')}</h1>
                <div className={"h-2/3 flex items-center"}>
                    <span className={"text-lg text-red-900"}>{t('UNAUTHORIZED.message')}</span>
                </div>

                <div className={"w-full flex justify-center"}>
                    <button onClick={() => navigate("/")} className={"w-32 bg-red-900 text-red-50 font-bold p-2 mb-5 rounded-[5px] cursor-pointer text-md"}>{t('HEADER_GO_BACK')}</button>
                </div>
            </div>
        </div>
    )
}

export default Unauthorized;
